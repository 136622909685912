import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      d="M8.5 15.2H4.7a2.9 2.9 0 0 1-2.9-3v0a3 3 0 0 1 3-2.9h6.7a2.9 2.9 0 0 0 0-5.8h-3m3.3 13.3v-5m0 0H16v3.4h-4.2v-3.4ZM6 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      stroke="#464E67"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default Icon;
