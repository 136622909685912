import {
  ComponentProps,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import clsx from "clsx";

import FormGroup from "../FormGroup";
import { InputType } from "./types";

type InputOwnProps = {
  id?: string;
  name?: string;
  fullWidth?: boolean;
  required?: boolean;
  className?: string;
  type?: InputType | "textarea";
  placeholder?: string;
  rows?: number;
  leftAdornments?: ReactNode;
  rightAdornments?: ReactNode;
  containerClassname?: string;
  formGroupClassName?: string;
} & Pick<ComponentProps<typeof FormGroup>, "rightLabel" | "label" | "error" | "helperText">;

type NativeInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type InputProps = Omit<NativeInputProps, "type"> & InputOwnProps;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      id,
      name,
      fullWidth,
      required,
      error,
      className,
      rightLabel,
      helperText,
      type = "text",
      rows,
      leftAdornments,
      rightAdornments,
      containerClassname,
      disabled,
      placeholder,
      formGroupClassName,
      ...props
    },
    ref
  ) => {
    const otherProps = {} as Pick<NativeInputProps, "aria-required" | "aria-invalid" | "required">;

    if (required) {
      otherProps["aria-required"] = "true";
      otherProps.required = true;
    }

    if (error) {
      otherProps["aria-invalid"] = "true";
    }

    const input =
      type === "textarea" ? (
        <textarea
          // @ts-ignore
          ref={ref}
          id={id || name}
          name={name}
          {...props}
          {...otherProps}
          rows={rows}
          className={clsx("block form-input", fullWidth && "w-full", className)}
        />
      ) : (
        <div
          className={clsx(
            "form-input flex items-stretch focus-within:outline",
            "focus-within:border-transparent outline-2 -outline-offset-1 outline-blue-500",
            error ? "outline-red-500" : undefined,
            fullWidth ? "w-full" : "w-min",
            containerClassname,
            disabled && "cursor-not-allowed bg-snow-grey"
          )}
        >
          {leftAdornments && (
            <div className={clsx("mr-3 text-gray-400", error && "text-inherit")}>
              {leftAdornments}
            </div>
          )}
          <input
            ref={ref}
            id={id || name}
            name={name}
            {...props}
            {...otherProps}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            className={clsx(
              "block",
              "outline-none bg-inherit",
              // Native html Input Date and Input Time are taller than the others...
              (type === "date" || type === "time") && "h-6",
              fullWidth && "w-full",
              className,
              disabled && "cursor-not-allowed text-gray-400"
            )}
          />
          {rightAdornments && (
            <div
              className={clsx(
                "ml-3 text-gray-400 flex flex-col justify-center",
                error && "text-inherit"
              )}
            >
              {rightAdornments}
            </div>
          )}
        </div>
      );
    return label ? (
      <FormGroup
        label={label}
        id={id || name}
        rightLabel={rightLabel}
        error={error}
        helperText={helperText}
        className={formGroupClassName}
      >
        {input}
      </FormGroup>
    ) : (
      input
    );
  }
);

Input.displayName = "Input";

export default Input;
