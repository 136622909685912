import { useEffect } from "react";

import analytics from "@booking/lib/analytics";

type UseTrackPageViewOptions = {
  pageType?: string;
  properties?: Omit<Parameters<typeof analytics.page>[0], "type">;
  disabled?: boolean;
};

const useTrackPageView = (options: UseTrackPageViewOptions = {}) => {
  const { pageType, properties, disabled } = options;

  useEffect(() => {
    if (!disabled) {
      analytics.page({
        type: pageType,
        ...properties,
      });
    }
  }, [pageType, disabled, properties]);
};

export default useTrackPageView;
