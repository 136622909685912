/**
 * Do not edit directly
 * Generated on Wed, 21 Sep 2022 14:12:09 GMT
 */

export const assetFaviconProdDefault = "v1663155001/app/favicons/finishers-prod-favicon";
export const assetFaviconProdAdmin = "v1663155001/app/favicons/finishers-prod-favicon-admin";
export const assetFaviconProdMaskable = "v1663155001/app/favicons/finishers-prod-favicon-maskable";
export const assetFaviconStagingDefault = "v1663155001/app/favicons/finishers-staging-favicon";
export const assetFaviconStagingAdmin = "v1663155001/app/favicons/finishers-staging-favicon-admin";
export const assetFaviconDevDefault = "v1663155001/app/favicons/finishers-dev-favicon";
export const assetFaviconDevAdmin = "v1663155001/app/favicons/finishers-dev-favicon-admin";
export const assetBgSectionBackgroundImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAAAXNSR0IArs4c6QAAABtJREFUeNpjGAWjYLCBm/dv3idP0ygYBYMMAACHRAbh+g6y4QAAAABJRU5ErkJggg==";
export const assetStylesheetFontSaira = "https://fonts.googleapis.com/css2?family=Saira:ital,wdth,wght@0,100,600;0,125,700;1,125,700&display=swap";
export const assetStylesheetFontGreycliff = "https://d3nwjb4s8t59v5.cloudfront.net/fonts/greycliff/style-6.css";
export const breakpointXs = 0;
export const breakpointSm = 600;
export const breakpointMd = 900;
export const breakpointLg = 1024;
export const breakpointXl = 1440;
export const breakpoint2xl = 1536;
export const colorDarkBlue = "0, 0, 51";
export const colorElectricBlue = "0, 82, 255";
export const colorFjordGrey = "70, 78, 103";
export const colorStormGrey = "110, 118, 143";
export const colorSnowGrey = "243, 243, 244";
export const colorMidGrey = "223, 223, 234";
export const colorOrange = "255, 105, 70";
export const colorWhite = "255, 255, 255";
export const colorGreen50 = "240, 253, 244";
export const colorGreen100 = "220, 252, 231";
export const colorGreen200 = "187, 247, 208";
export const colorGreen300 = "134, 239, 172";
export const colorGreen400 = "74, 222, 128";
export const colorGreen500 = "34, 197, 94";
export const colorGreen600 = "22, 163, 74";
export const colorGreen700 = "21, 128, 61";
export const colorGreen800 = "22, 101, 52";
export const colorGreen900 = "20, 83, 45";
export const colorGreenDefault = "34, 197, 94";
export const colorRed50 = "254, 242, 242";
export const colorRed100 = "254, 226, 226";
export const colorRed200 = "254, 202, 202";
export const colorRed300 = "252, 165, 165";
export const colorRed400 = "248, 113, 113";
export const colorRed500 = "239, 68, 68";
export const colorRed600 = "220, 38, 38";
export const colorRed700 = "185, 28, 28";
export const colorRed800 = "153, 27, 27";
export const colorRed900 = "127, 29, 29";
export const colorRedDefault = "239, 68, 68";
export const colorBlue50 = "224, 234, 255";
export const colorBlue100 = "179, 203, 255";
export const colorBlue200 = "134, 173, 255";
export const colorBlue300 = "90, 142, 255";
export const colorBlue400 = "45, 112, 255";
export const colorBlue500 = "0, 82, 255";
export const colorBlue600 = "0, 50, 206";
export const colorBlue700 = "0, 23, 143";
export const colorBlue800 = "0, 7, 93";
export const colorBlue900 = "0, 0, 51";
export const colorBlueDefault = "0, 82, 255";
export const colorBgSectionDefault = "255, 255, 255";
export const colorBgSectionHighlighted = "243, 243, 244";
export const colorTextDefault = "0, 0, 51";
export const colorTextSecondary = "70, 78, 103";
export const colorTextTertiary = "110, 118, 143";
export const shadow2xl = ["0px 100px 80px rgba(3, 27, 80, 0.07)","0px 64.8148px 46.8519px rgba(3, 27, 80, 0.0531481)","0px 38.5185px 25.4815px rgba(3, 27, 80, 0.0425185)","0px 20px 13px rgba(3, 27, 80, 0.035)","0px 8.14815px 6.51852px rgba(3, 27, 80, 0.0274815)","0px 1.85185px 3.14815px rgba(3, 27, 80, 0.0168519)"];