/* eslint-disable no-irregular-whitespace */
import * as Sentry from "@sentry/react";
import { ComponentProps, ReactNode, useCallback, useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import clsx from "clsx";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import useWindowSize from "react-use/lib/useWindowSize";

import EmailRoundIcon from "@kavval/ui/Icons/EmailRoundIcon";
import FacebookRoundIcon from "@kavval/ui/Icons/FacebookRoundIcon";
import TwitterRoundIcon from "@kavval/ui/Icons/TwitterRoundIcon";

import { PaymentStatusEnum, RegistrationStatusEnum } from "@api/db/models/Order/types";

import {
  GenericNotFoundError,
  LoadingCentered,
  NotFoundMessage,
  PageTitle,
  ShoppingCart,
  ShoppingCartLayout,
} from "@booking/components";
import PageWrapper from "@booking/components/PageWrapper";
import { formatDate } from "@booking/lib/formatters";
import useTrackPageView from "@booking/lib/hooks/useTrackPageView";
import { Output, trpc } from "@booking/lib/trpc";

import styles from "./styles.module.css";

const DetailWithLabel = ({
  label,
  children,
  className,
}: {
  label: ReactNode;
  children: ReactNode;
  className?: string;
}) => (
  <div className={`${className || ""}`}>
    <div className="font-semibold text-sm text-storm-grey">{label}</div>
    <div className="font-bold text-lg leading-none">{children}</div>
  </div>
);

// export enum RegistrationStatus {
//   Registered = "registered",
//   Cancelled = "cancelled",
//   PartiallyCancelled = "partially_cancelled",
//   Failed = "failed",
// }

// export enum PaymentStatus {
//   AwaitingPayment = "awaiting_payment",
//   ProcessingPayment = "processing_payment",
//   Paid = "paid",
//   Failed = "failed",
//   PartiallyRefunded = "partially_refunded",
//   Refunded = "refunded",
// }

const Status = ({
  registrationStatus,
  paymentStatus,
}: {
  registrationStatus: RegistrationStatusEnum;
  paymentStatus: PaymentStatusEnum;
}) => {
  let message;
  let className = "text-electric-blue";

  if (registrationStatus === "registered") {
    if (paymentStatus === "paid") {
      message = t({
        id: "page.confirmation.registrationConfirmed",
        message: "Inscription confirmée",
      });
    } else if (paymentStatus === "partially_refunded") {
      message = t({
        id: "page.confirmation.registrationConfirmedButPartiallyRefunded",
        message: "Inscription confirmée mais partiellement remboursée",
      });
    } else if (paymentStatus === "refunded") {
      message = t({
        id: "page.confirmation.registrationConfirmedButRefunded",
        message: "Inscription remboursée mais confirmée",
      });
    }
  } else if (registrationStatus === "cancelled") {
    className = "text-red-500";
    if (paymentStatus === "paid") {
      message = t({
        id: "page.confirmation.registrationCancelled",
        message: "Inscription annulée et non remboursée",
      });
    } else if (paymentStatus === "partially_refunded") {
      message = t({
        id: "page.confirmation.registrationCancelledButPartiallyRefunded",
        message: "Inscription annulée mais partiellement remboursée",
      });
    } else if (paymentStatus === "refunded") {
      message = t({
        id: "page.confirmation.registrationCancelledAndRefunded",
        message: "Inscription annulée et remboursée",
      });
    }
  } else {
    message = t({
      id: "page.confirmation.unknownRegistrationStatus",
      message: "Statut inconnu, veuillez-nous contacter pour plus d'informations",
    });
    className = "text-red-500";
    // eslint-disable-next-line lingui/no-unlocalized-strings
    Sentry.captureException(`No translation for registrationStatus='${registrationStatus}`);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.error(`No translation for registrationStatus='${registrationStatus}`);
  }

  return (
    <span className={`mt-1 uppercase inline-block leading-tight font-bold text-base ${className}`}>
      {message}
    </span>
  );
};

const Section = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={clsx("rounded-sm shadow-2xl p-4 md:p-8 mb-6 md:mb-12", className)}>
    {children}
  </div>
);

const SectionTitle = ({ children }: { children: ReactNode }) => (
  <div className="text-xl md:text-2xl font-bold mb-6 leading-tight">{children}</div>
);

const Ticket = ({
  order,
  registration,
}: {
  order: Output["getOrderByShortId"];
  registration: Output["getOrderByShortId"]["registrations"][number];
}) => {
  const { i18n } = useLingui();
  const { participant, registrationStatus, paymentStatus } = registration;
  const { event } = registration.offer.raceEdition!.race;

  const eventName = event.name;
  const shareText = t({
    id: "page.confirmation.shareText",
    message: `"${eventName}" : Rejoins-moi en prenant ton dossard sur Finishers !`,
  });

  return (
    <>
      <Section className="bg-white">
        <SectionTitle>
          <Trans id="page.confirmation.orderSummaryTitle">Récapitulatif de ta commande</Trans>
        </SectionTitle>
        <div className="grid md:grid-cols-2 gap-6">
          <DetailWithLabel
            label={t({ id: "page.confirmation.participant.name", message: "Participant" })}
          >
            {participant.firstName || ""} {participant.lastName || ""}
          </DetailWithLabel>
          <DetailWithLabel
            label={t({ id: "page.confirmation.participant.email", message: "Email" })}
          >
            {participant.email || ""}
          </DetailWithLabel>
          {participant.birthdate && (
            <DetailWithLabel
              label={t({
                id: "page.confirmation.participant.birthdate",
                message: "Date de naissance",
              })}
            >
              {formatDate(i18n.locale, participant.birthdate, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
            </DetailWithLabel>
          )}
          {participant.gender && (
            <DetailWithLabel
              label={t({ id: "page.confirmation.participant.gender", message: "Sexe" })}
            >
              {participant.gender}
            </DetailWithLabel>
          )}
          <DetailWithLabel
            label={t({
              id: "page.confirmation.orderShortId",
              message: "Numéro de réservation",
            })}
          >
            {order.shortId}
          </DetailWithLabel>
          <DetailWithLabel
            label={t({
              id: "page.confirmation.registrationStatus",
              message: "Statut de réservation",
            })}
          >
            <Status registrationStatus={registrationStatus} paymentStatus={paymentStatus} />
          </DetailWithLabel>
          {order.receiptUrl && (
            <DetailWithLabel
              label={t({
                id: "page.confirmation.receiptLabel",
                message: "Justificatif de paiement",
              })}
            >
              <a href={order.receiptUrl} target="_blank" rel="noreferrer">
                <Trans id="page.confirmation.paymentReceipt">Télécharger le reçu</Trans>
              </a>
            </DetailWithLabel>
          )}
        </div>
        <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-6 mt-6">
          <div className="flex flex-col justify-end items-start">
            <a
              href={`/book/event/${event.shortId}`}
              className="button button-primary w-full sm:w-auto"
            >
              <Trans id="page.confirmation.orderAgainButton">
                Acheter un dossard supplémentaire
              </Trans>
            </a>
          </div>
          <div>
            <DetailWithLabel
              label={t({
                id: "page.confirmation.shareRegistration",
                message: "Partager mon inscription",
              })}
            >
              <div className="mt-2">
                <FacebookShareButton
                  url={`/book/event/${event.shortId}`}
                  quote={shareText}
                  resetButtonStyle={false}
                  // TODO ANALYTICS
                  className={styles.ShareButton}
                >
                  <FacebookRoundIcon />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`/book/event/${event.shortId}`}
                  resetButtonStyle={false}
                  // TODO ANALYTICS
                  className={styles.ShareButton}
                >
                  <TwitterRoundIcon />
                </TwitterShareButton>
                <EmailShareButton
                  url={`/book/event/${event.shortId}`}
                  subject={shareText}
                  resetButtonStyle={false}
                  // TODO ANALYTICS
                  className={styles.ShareButton}
                >
                  <EmailRoundIcon />
                </EmailShareButton>
              </div>
            </DetailWithLabel>
          </div>
        </div>
      </Section>
      <Section className="bg-electric-blue text-white">
        <SectionTitle>
          <Trans id="page.confirmation.finalizeRegistrationTitle">
            Tu es prêt à finaliser ton dossier ? ✌️️
          </Trans>
        </SectionTitle>
        <p>
          <Trans id="page.confirmation.finalizeRegistrationText">
            Ne perds pas de temps et fournis dès à présent les pièces manquantes dans ton dossier :
            certificat medical, infos complémentaires...
          </Trans>
        </p>

        {/* TODO ANALYTICS */}
        <a
          href={registration.editRegistrationUrl!}
          target="_blank"
          rel="noreferrer noopener"
          className="button button-dark w-full sm:w-auto mt-4"
        >
          <Trans id="page.confirmation.finalizeRegistrationButton">
            Finaliser mon dossier d’inscription
          </Trans>
        </a>
      </Section>
    </>
  );
};

const Confirmation = () => {
  const { orderShortId } = useParams();
  const { isLoading, error, data } = trpc.getOrderByShortId.useQuery(orderShortId!, { retry: 0 });
  const { width, height } = useWindowSize();
  const [party, setParty] = useState(/confirmSuccess=1/.test(document.location.search));
  const restartConfetti = useCallback(() => setParty(true), []);
  const endConfetti = useCallback<
    NonNullable<ComponentProps<typeof Confetti>["onConfettiComplete"]>
  >((confetti) => {
    setParty(false);
    confetti?.reset();
  }, []);
  useTrackPageView({ pageType: "book/checkout/confirmation" });

  // TODO gérer le cas où order.status !== confirmed
  useEffect(() => {
    if (!isLoading) {
      if (error) {
        Sentry.captureException(error);
      }
    }
  }, [data, isLoading, error]);

  if (error) {
    if (error.data?.code === "NOT_FOUND") {
      return (
        <PageWrapper>
          <NotFoundMessage
            title={t({
              id: "page.confirmation.error.notFound.title",
              message: "Commande non trouvée",
            })}
            tracking={{ disabled: true }}
          >
            <p>
              <Trans id="page.confirmation.error.notFound.message">
                Désolé, cette commande n'existe pas
              </Trans>
            </p>
            <p>
              <a className="button button-primary mt-8" href="/account/orders">
                <Trans id="page.confirmation.error.notFound.backToOrders">Voir mes commandes</Trans>
              </a>
            </p>
          </NotFoundMessage>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <GenericNotFoundError tracking={{ disabled: true }} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <ShoppingCartLayout
        rightContent={
          isLoading || !data ? null : (
            <ShoppingCart
              cart={{
                items: data.registrations.map(({ offer, options }) => ({
                  offer,
                  options,
                })),
              }}
              isPaid
              total={data.amount}
            />
          )
        }
      >
        <PageTitle
          title={
            <>
              <Trans id="page.confirmation.title">Ton dossard est réservé !</Trans>
              <span onClick={restartConfetti} className="cursor-pointer">
                &nbsp;🎉
              </span>
            </>
          }
          subtitle={t({
            id: "page.confirmation.subtitle",
            message:
              "Place à l’entraînement ! C’est parti pour les séances de côtes et de fractionné ! 👟",
          })}
        />

        {isLoading ? (
          <LoadingCentered />
        ) : (
          <>
            {data.registrations.map((registration, index) => (
              <Ticket key={index} order={data} registration={registration} />
            ))}
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={party ? Math.floor(width / 4) : 0}
              style={{ pointerEvents: "none", position: "fixed" }}
              initialVelocityY={-10}
              onConfettiComplete={endConfetti}
            />
          </>
        )}
      </ShoppingCartLayout>
    </PageWrapper>
  );
};

export default Confirmation;
