import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      d="M27.092 1a1.32 1.32 0 011.303 1.146l.012.18v1.498c5.402.37 8.71 3.862 8.693 9.499v16.128c0 5.976-3.714 9.549-9.683 9.549H12.583C6.62 39 2.9 35.366 2.9 29.318V13.323c0-5.635 3.318-9.128 8.709-9.499V2.326A1.32 1.32 0 0112.924 1a1.32 1.32 0 011.303 1.146l.012.18V3.79h11.537V2.326A1.32 1.32 0 0127.093 1zm7.377 15.738H5.528l.002 12.58c0 4.417 2.364 6.87 6.62 7.023l.432.008h14.834c4.543 0 7.052-2.415 7.052-6.898V16.738zm-6.662 11.12a1.32 1.32 0 011.315 1.326c0 .671-.495 1.226-1.137 1.313l-.178.013a1.324 1.324 0 01-1.332-1.326c0-.671.495-1.226 1.137-1.314l.195-.012zm-7.783 0a1.32 1.32 0 011.316 1.326c0 .671-.495 1.226-1.137 1.313l-.179.013a1.324 1.324 0 01-1.331-1.326c0-.671.494-1.226 1.137-1.314l.194-.012zm-7.799 0a1.32 1.32 0 011.316 1.326c0 .671-.495 1.226-1.137 1.313l-.195.013a1.32 1.32 0 01-1.315-1.326c0-.671.495-1.226 1.137-1.314l.194-.012zm15.582-6.869a1.32 1.32 0 011.315 1.326c0 .67-.495 1.226-1.137 1.313l-.178.012a1.324 1.324 0 01-1.332-1.325c0-.671.495-1.226 1.137-1.314l.195-.012zm-7.783 0a1.32 1.32 0 011.316 1.326c0 .67-.495 1.226-1.137 1.313l-.179.012a1.324 1.324 0 01-1.331-1.325c0-.671.494-1.226 1.137-1.314l.194-.012zm-7.799 0a1.32 1.32 0 011.316 1.326c0 .67-.495 1.226-1.137 1.313l-.195.012a1.32 1.32 0 01-1.315-1.325c0-.671.495-1.226 1.137-1.314l.194-.012zM25.776 6.442H14.239v1.7a1.32 1.32 0 01-1.315 1.325 1.32 1.32 0 01-1.303-1.145l-.012-.18-.002-1.66c-3.921.329-6.076 2.7-6.076 6.841l-.001.762h28.938l.001-.766c.013-4.144-2.131-6.51-6.062-6.838v1.66a1.32 1.32 0 01-1.315 1.326 1.32 1.32 0 01-1.303-1.145l-.012-.18-.001-1.7z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default Icon;
