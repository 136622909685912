import { type Locale } from "@kavval/lingui-config/locales";

type FormatPrice = (params: {
  price: number;
  currency: string;
  locale: Locale;
  alwaysShowDigits?: boolean;
}) => string;
export const formatPrice: FormatPrice = ({ price, currency, locale, alwaysShowDigits }) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency,
    minimumFractionDigits: alwaysShowDigits || price % 100 !== 0 ? 2 : 0,
  }).format(price / 100);
