import { isEmpty } from "lodash";

import { IdentifyFn, PageFn, ResetFn, TrackFn } from "..";
import { TrackEventOptions } from "../types";

let isIdentified = false;

const identify: IdentifyFn = (user) => {
  if (!isIdentified) {
    // @ts-ignore
    window.fbq("init", "2905684433018550", {
      em: user.email,
      // @ts-ignore
      fn: !isEmpty(user?.firstName) ? user.firstName.toLowerCase() : null,
      // @ts-ignore
      ln: !isEmpty(user?.lastName) ? user.lastName.toLowerCase() : null,
      ge: user.gender,
      external_id: user.id,
      db: user.birthdate ? user.birthdate.replace("-", "") : null,
    });
    isIdentified = true;
  }
};

const createFacebookBasicProductProperties = (properties: TrackEventOptions["view_item"][0]) => {
  const { value, currency, items } = properties;

  return {
    content_ids: items.map(({ item_id: id }) => id),
    content_category: [
      items?.[0].item_category,
      items?.[0].item_category2,
      items?.[0].item_category3,
      items?.[0].item_category4,
      items?.[0].item_category5,
    ]
      .filter(Boolean)
      .join(" > "),
    content_name: items[0].item_brand, // Nom de la course
    content_type: "product",
    currency,
    value,
  };
};

const createFacebookCartProperties = (properties: TrackEventOptions["view_item"][0]) => {
  const { items } = properties;

  return {
    ...createFacebookBasicProductProperties(properties),
    content_name: items[0].item_name,
    content_type: "product",
  };
};

const createFacebookPurchaseProperties = (properties: TrackEventOptions["view_item"][0]) => {
  const { items } = properties;

  return {
    ...createFacebookBasicProductProperties(properties),
    contents: items.map(({ item_id: id }) => ({ id, quantity: 1 })),
    content_type: "product",
  };
};

const track: TrackFn = (event, ...args) => {
  // https://developers.facebook.com/docs/facebook-pixel/reference
  // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#object-properites

  if (event === "search") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["search"][0];
    // @ts-ignore
    return window.fbq("track", "Search", {
      search_string: properties.search_term,
    });
  }

  if (event === "generic_addToFavorites") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["generic_addToFavorites"][0];
    // @ts-ignore
    return window.fbq("track", "AddToWishlist", {
      content_ids: [`event:${properties.id}`], // Id de l'évènement
      content_type: "product",
      // content_name, // Nom de l'évènement
      content_category: "event", // Catégorie de la page (Trail, Course sur route ?)
      // currency, //
      // value // Valeur de l'événement
    });
  }

  if ((event as "add_to_cart") === "add_to_cart") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["add_to_cart"][0];
    // @ts-ignore
    return window.fbq("track", "AddToCart", createFacebookCartProperties(properties));
  }

  if (event === "begin_checkout") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["begin_checkout"][0];
    // @ts-ignore
    return window.fbq("track", "InitiateCheckout", createFacebookCartProperties(properties));
  }

  if (event === "checkout_progress") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["checkout_progress"][0];
    const { checkout_step } = properties;

    if (checkout_step === 1 /* auth */) {
      // nothing (yet ?)
    } else if (checkout_step === 2 /* form */) {
      // nothing (yet ?)
    } else if (checkout_step === 3 /* payment */) {
      // nothing (yet ?)
    }
  }

  if (event === "purchase") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["purchase"][0];
    // @ts-ignore
    return window.fbq("track", "Purchase", createFacebookPurchaseProperties(properties));
  }

  if (event === "auth_signup") {
    // @ts-ignore
    return window.fbq("track", "CompleteRegistration");
  }

  if (event === "view_item") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["view_item"][0];
    // @ts-ignore
    return window.fbq("track", "ViewContent", createFacebookCartProperties(properties));
  }

  // FIXME gérer view_item

  // @ts-ignore
  window.fbq("trackCustom", event as any, args[0]);
};

const page: PageFn = () => {
  // @ts-ignore
  return window.fbq("track", "PageView");
};

// eslint-disable-next-line
const reset: ResetFn = () => {};

export default {
  identify,
  reset,
  track,
  page,
};
