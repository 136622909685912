import { ComponentProps, FC } from "react";

import { colorElectricBlue } from "@kavval/design-tokens";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <circle cx="6" cy="6" r="6" fill={`rgb(${colorElectricBlue})`} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.854 4.146a.5.5 0 010 .708l-3.5 3.5a.5.5 0 01-.708 0l-1-1a.5.5 0 11.708-.708L5 7.293l3.146-3.147a.5.5 0 01.708 0z"
      fill="#fff"
    />
  </SvgIcon>
);

export default Icon;
