import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.861 26.281l.968-2.258c.364.79.881 1.512 1.548 2.103.285.254.598.484.938.684l.001.001.697.412-.575 1.342a3.134 3.134 0 01-2.886 1.903H8.485a2.094 2.094 0 010-4.187h4.376zm6.045 2.24l-.397.927-.001.002a5.385 5.385 0 01-4.956 3.27H8.485a4.345 4.345 0 010-8.69h2.892l2.38-5.552.123-.285a4.345 4.345 0 01-4.898-7.162m0 0l2.596-1.99a8.506 8.506 0 017.185-1.524l.058.014.102.03a5.392 5.392 0 118.18 3.366 8.656 8.656 0 011.848 2.506l1.088 2.222h1.476a4.344 4.344 0 110 8.69h-3.199a5.392 5.392 0 011.06 4.939l-.009.028-2.079 6.642a4.346 4.346 0 01-5.444 2.846 4.344 4.344 0 01-2.848-5.444l1.264-4.036-1.354-.8m4.036-.23l-1.798 5.739a2.093 2.093 0 003.996 1.251l2.07-6.612a3.154 3.154 0 00-1.416-3.559l-4.006-2.364 2.048-5.122 1.326 2.71.008.017a3.16 3.16 0 002.813 1.743h3.532a2.094 2.094 0 000-4.187h-2.88l-1.706-3.484a6.372 6.372 0 00-2.742-2.832 5.975 5.975 0 00-1.3-.5l-4.65-1.384a6.255 6.255 0 00-5.29 1.122l-2.595 1.99A2.094 2.094 0 0012.9 16.14l2.597-1.99c.053-.042.109-.08.165-.116.472-.297 1.03-.395 1.488-.287l.962.286-2.451 5.718a4.166 4.166 0 00-.296 2.222c.057.41.175.808.347 1.183a4.17 4.17 0 001.669 1.849l5.56 3.283zm.373-18.88a3.143 3.143 0 01-2.268-3.018 3.14 3.14 0 112.268 3.018z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default Icon;
