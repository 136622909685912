import Plausible from "plausible-tracker";

import { PageFn, TrackFn } from "..";
import { EventName, TrackEventOptions } from "../types";

let plausible: ReturnType<typeof Plausible>;

const setup = ({ plausibleApiUrl }: { plausibleApiUrl: string }) => {
  plausible = Plausible({
    domain: "kavval.com",
    apiHost: plausibleApiUrl,
  });
};

const ignoredEvents: Array<EventName> = [
  "view_item",
  "search",
  "searchbar_closed",
  "searchbar_opened",
];

const track: TrackFn = (event, ...args) => {
  // C'est trop couteux de tracker ça dans plausible
  if (ignoredEvents.includes(event)) return;

  if (event === "auth_signup") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["auth_signup"][0];
    return plausible?.trackEvent("sign_up", {
      props: {
        // @ts-ignore
        method: properties.method,
      },
    });
  }
  if (event === "share") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["share"][0];
    const { method, url, type } = properties;
    return plausible?.trackEvent("share", {
      props: {
        // @ts-ignore
        method,
        // @ts-ignore
        pageType: type,
        // @ts-ignore
        url: url,
      },
    });
  }

  if (event === "auth_login") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["auth_login"][0];
    return plausible?.trackEvent("login", {
      props: {
        // @ts-ignore
        method: properties.method,
      },
    });
  }

  if (event === "generic_addToFavorites") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["generic_addToFavorites"][0];
    return plausible?.trackEvent("add_to_wishlist", {
      props: {
        // @ts-ignore
        eventId: properties.id,
      },
    });
  }

  if (event === "click_featured_event") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["click_featured_event"][0];
    plausible?.trackEvent(event, { props: { eventHref: properties.event_label } });
  }

  // @ts-ignore
  plausible?.trackEvent(event, { props: args?.[0] });
};

const page: PageFn = (properties) => {
  plausible?.trackPageview(
    {},
    {
      props: {
        pageType: properties.type,
      },
    }
  );
};

export default {
  setup,
  track,
  page,
};
