import { i18n } from "@lingui/core";

import {
  formatDate as kavvalFormatDate,
  formatDateRange as kavvalFormatDateRange,
  // formatDistance as kavvalFormatDistance,
  formatNumber as kavvalFormatNumber,
  formatPrice as kavvalFormatPrice,
  toHumanList as kavvalToHumanList,
} from "@kavval/formatters";

export { formatGenericDistance as formatDistance } from "@kavval/formatters";

export const formatPrice = (price, currency) =>
  kavvalFormatPrice({ price, currency, locale: i18n.locale });

export const formatNumber = (number) => kavvalFormatNumber({ number, locale: i18n.locale });

export const formatDate = (locale, date, format) => kavvalFormatDate(locale, date, format);

export const formatDateRange = (locale, start, end, options) =>
  kavvalFormatDateRange(locale, start, end, options);

export const toHumanList = (items, lastJoin = " & ") => kavvalToHumanList(items, lastJoin);
