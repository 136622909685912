import { isEqual, mean } from "lodash";
import times from "lodash/times";

import {
  GenericDiscipline,
  MultiSportsDisciplineEnum,
  MultisportsDistances,
} from "@kavval/constants";

import { getScore } from "./helpers";

const disciplineConfigs = MultisportsDistances;

type DisciplineConfigsKeys = keyof typeof disciplineConfigs;

type DisciplineRanges = {
  [key in DisciplineConfigsKeys]: {
    letters: string[];
    ranges: number[][];
  };
};

const disciplineRanges = (
  Object.keys(disciplineConfigs) as DisciplineConfigsKeys[]
).reduce<DisciplineRanges>((acc, key) => {
  const activitiesCount = disciplineConfigs[key].letters[0].activities.length;
  acc[key] = {
    letters: disciplineConfigs[key].letters.map(({ name }) => name),
    ranges: times(activitiesCount, (i) =>
      disciplineConfigs[key].letters.map(({ activities }) => activities[i])
    ),
  };
  return acc;
}, {} as DisciplineRanges);

type Args<TGenericDiscipline extends GenericDiscipline> = {
  discipline: TGenericDiscipline;
  activities: Array<{ distance: number }>;
};

export const getDistanceCategory = <TArgs extends Args<GenericDiscipline>>({
  discipline,
  activities,
}: TArgs) => {
  if (Object.keys(disciplineConfigs).includes(discipline)) {
    const d = discipline as DisciplineConfigsKeys;

    // On vérifie d'abord s'il y a une correspondance avec les distances exactes
    const config = disciplineConfigs[d];

    const match = config.exactDistances.find(({ activities: specificActivities }) =>
      isEqual(
        specificActivities,
        activities.map(({ distance }) => distance)
      )
    );
    if (match) return match.name;

    // Sinon on calcule la moyenne des scores de chaque activité
    const { ranges } = disciplineRanges[d];
    const scores = activities?.map(({ distance }, index) => getScore(ranges[index], distance));
    const letterIndex = Math.round(mean(scores));
    return disciplineRanges[d].letters[letterIndex] as (typeof config)["letters"][number]["name"];
  }

  if (discipline === MultiSportsDisciplineEnum.SwimRun) {
    const globalDistance = activities.reduce((acc, { distance }) => acc + distance, 0);
    // Need to handle swim run gravity and FFtri
    if (globalDistance <= 12500) return "S";
    if (globalDistance > 12500 && globalDistance <= 20000) return "M";
    if (globalDistance > 20000) return "L";
  }

  return null;
};
