import { i18n, Messages } from "@lingui/core";
import { detect, fromCookie, fromNavigator, fromUrl } from "@lingui/detect-locale";
import localeParser from "accept-language-parser";

import productionLocales, { type Locale } from "@kavval/lingui-config/locales";

import frMessages from "./i18nMessagesBundles/fr";

const locales =
  import.meta.env.NODE_ENV === "production" ? productionLocales : [...productionLocales, "zu-ZA"];

let currentLocale: Locale;

export function getCurrentLocale(force = false) {
  if (currentLocale && !force) return currentLocale;

  const localeDetected = detect(
    fromUrl("lang"),
    fromCookie("NEXT_LOCALE"),
    fromNavigator(),
    "fr"
  ) as Locale;

  const localeMatched = (localeParser.pick([...locales], localeDetected, {
    loose: true,
  }) || "fr") as Locale;

  currentLocale = localeMatched;

  return currentLocale;
}

const messageLoaders: Record<Locale | "zu-ZA", () => Promise<{ default: Messages }>> = {
  fr: () => import(`./i18nMessagesBundles/fr`),
  da: () => import(`./i18nMessagesBundles/da`),
  de: () => import(`./i18nMessagesBundles/de`),
  el: () => import(`./i18nMessagesBundles/el`),
  en: () => import(`./i18nMessagesBundles/en`),
  es: () => import(`./i18nMessagesBundles/es`),
  it: () => import(`./i18nMessagesBundles/it`),
  nl: () => import(`./i18nMessagesBundles/nl`),
  no: () => import(`./i18nMessagesBundles/no`),
  pt: () => import(`./i18nMessagesBundles/pt`),
  sv: () => import(`./i18nMessagesBundles/sv`),
  pl: () => import(`./i18nMessagesBundles/pl`),
  hu: () => import(`./i18nMessagesBundles/hu`),
  cs: () => import(`./i18nMessagesBundles/cs`),
  // $NEW_LOCALE: () => import(`./i18nMessagesBundles/$NEW_LOCALE`), @DUPLICATE_FOR_NEW_LOCALE
  "zu-ZA": () => import(`./i18nMessagesBundles/fr`),
};

export async function initLocale(force = false) {
  const locale = getCurrentLocale(force);

  i18n.load(locale, locale === "fr" ? frMessages : (await messageLoaders[locale]()).default);

  i18n.activate(locale);
}

export default i18n;
