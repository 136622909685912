"use client";

import { ComponentProps } from "react";
import { useLingui } from "@lingui/react";
import clsx from "clsx";

import { ActivityEnum } from "@kavval/constants";
import { formatGenericDistance, translateDiscipline } from "@kavval/formatters";
import { type Locale } from "@kavval/lingui-config/locales";

import ActivityIcon from "../ActivityIcon";
import Chip from "../Chip";

interface Props extends Pick<ComponentProps<typeof Chip>, "size" | "className"> {
  distance: Parameters<typeof formatGenericDistance>[1];
  distanceUnit: Parameters<typeof formatGenericDistance>[2];
  activity?: ActivityEnum;
}

const DistanceChip = ({ distance, distanceUnit, size, className, activity }: Props) => {
  const { i18n } = useLingui();
  const distanceFormatted = formatGenericDistance(i18n.locale as Locale, distance, distanceUnit);
  return (
    <Chip
      size={size}
      className={clsx("border-storm-grey/20 border", className)}
      icon={activity ? <ActivityIcon type={activity} /> : undefined}
      title={activity ? `${translateDiscipline(i18n, activity)} - ${distanceFormatted}` : undefined}
    >
      {distanceFormatted}
    </Chip>
  );
};

export default DistanceChip;
