import { FC } from "react";
import clsx from "clsx";

import Chip from "../Chip";
import RaceTag from "../RaceTag";
import styles from "./style.module.css";

interface MoreTagsProps {
  tags: string[];
}

const MoreTags: FC<MoreTagsProps> = ({ tags }) => {
  return (
    <>
      <Chip size="small" className={clsx(styles.Plus, "opacity-60")}>{`+${tags.length}`}</Chip>
      {tags.map((tag) => (
        <Chip key={tag} size="small" className={styles.HiddenTag}>
          <RaceTag tag={tag} />
        </Chip>
      ))}
    </>
  );
};

export default MoreTags;
