/* eslint-disable no-irregular-whitespace */
import { forwardRef, HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

interface Props {
  isBold?: boolean;
  label: string | ReactNode;
  open?: boolean;
  disabled?: boolean;
}

const SelectButton = forwardRef<HTMLButtonElement, Props & HTMLAttributes<HTMLButtonElement>>(
  ({ label, className, disabled, open, onClick, isBold = true, ...props }, ref) => (
    <button
      role="combobox"
      className={clsx(
        // On utilise "inline-flex" car "inline-block" ajoute des espaces avec "overflow:hidden" ("truncate")
        "justify-start font-sans normal-case form-select inline-flex min-w-[100px] max-w-full",
        !isBold && "font-normal",
        disabled && "form-input-disabled",
        className
      )}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
      aria-expanded={props["aria-expanded"] || open}
      aria-disabled={props["aria-disabled"] || disabled}
      {...props}
    >
      <span className="truncate">{label || " "}</span>
    </button>
  )
);

SelectButton.displayName = "SelectButton";

export default SelectButton;
