import { FC, useEffect } from "react";
import { isArray } from "lodash";

import analytics from "@booking/lib/analytics/index";

type TrackViewItemProps = {
  items: Parameters<typeof analytics.viewItem>[0];
};

const TrackViewItem: FC<TrackViewItemProps> = ({ items }) => {
  useEffect(() => {
    if (!items || (isArray(items) && items.length === 0)) return;
    analytics.viewItem(items);
  }, [items]);
  return null;
};

export default TrackViewItem;
