import { ReactElement, useState } from "react";

const CHARACTERS_TRESHOLD = 80;

const TextReveal = ({
  text,
  showMore,
  threshold = CHARACTERS_TRESHOLD,
}: {
  text: string;
  showMore: ReactElement;
  threshold?: number;
}) => {
  const [show, setShow] = useState(text.length <= threshold);

  if (show) {
    return text;
  }

  return (
    <div className="@container">
      <div className="@md:flex @md:flew-row">
        <div className="line-clamp-1 overflow-hidden text-ellipsis">{text}</div>
        <div
          className="text-electric-blue underline cursor-pointer flex-wrap shrink-0"
          onClick={() => setShow(true)}
        >
          {showMore}
        </div>
        {/* <div className="inline-block pl-2 @xs:after:content-['xs'] @sm:after:content-['sm'] @md:after:content-['md'] @lg:after:content-['lg'] @xl:after:content-['xl'] @2xl:after:content-['2xl'] @3xl:after:content-['3xl'] @4xl:after:content-['4xl'] @5xl:after:content-['5xl'] @6xl:after:content-['6xl'] @7xl:after:content-['7xl']"></div> */}
      </div>
    </div>
  );
};

export default TextReveal;
