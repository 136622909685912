import { type RemoveViteKeys } from "@kavval/ts-utils";

import { type Schema } from "./env-schema";

export const ENV_VARS = {
  NODE_ENV: import.meta.env.NODE_ENV,
  IS_PROD: import.meta.env.PROD,
  IS_DEV: import.meta.env.DEV,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
  STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  KAVVAL_DOMAIN: import.meta.env.VITE_KAVVAL_DOMAIN,
  KAVVAL_ENV: import.meta.env.VITE_KAVVAL_ENV,
} satisfies Omit<RemoveViteKeys<Schema>, "PUBLIC_URL"> & {
  NODE_ENV: "development" | "test" | "production";
  IS_DEV: boolean;
  IS_PROD: boolean;
};
