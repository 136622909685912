import { type Locale } from "@kavval/lingui-config/locales";

// pour l'instant Next ne supporte `satisfies`
export const localeEmojis: Record<Locale, string> = {
  de: "🇩🇪",
  en: "🇬🇧",
  es: "🇪🇸",
  fr: "🇫🇷",
  it: "🇮🇹",
  nl: "🇳🇱",
  no: "🇳🇴",
  pt: "🇵🇹",
  sv: "🇸🇪",
  da: "🇩🇰",
  el: "🇬🇷",
  pl: "🇵🇱",
  hu: "🇭🇺",
  cs: "🇨🇿",
};

export const localeNames: Record<Locale, string> = {
  de: `${localeEmojis.de} Deutsch`,
  en: `${localeEmojis.en} English`,
  es: `${localeEmojis.es} Español`,
  fr: `${localeEmojis.fr} Français`,
  it: `${localeEmojis.it} Italiano`,
  nl: `${localeEmojis.nl} Nederlands`,
  no: `${localeEmojis.no} Norsk`,
  pt: `${localeEmojis.pt} Português`,
  sv: `${localeEmojis.sv} Svenska`,
  da: `${localeEmojis.da} Dansk`,
  el: `${localeEmojis.el} Εληνικά`,
  pl: `${localeEmojis.pl} Polski`,
  hu: `${localeEmojis.hu} Magyar`,
  cs: `${localeEmojis.cs} Čeština`,
};
