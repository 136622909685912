import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="20" height="16" viewBox="0 0 20 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7992 0.836304L19.3491 14.1362C19.537 14.4284 19.5504 14.7999 19.3838 15.1049C19.2173 15.4101 18.8976 15.5998 18.5499 15.5998H1.45008C1.10258 15.5998 0.782778 15.4101 0.616171 15.1052C0.449641 14.8001 0.462962 14.4285 0.650905 14.1362L9.20082 0.836304C9.37568 0.564369 9.67671 0.399902 10 0.399902C10.3233 0.399902 10.6243 0.564369 10.7992 0.836304ZM11.8205 5.93837C10.9686 4.61328 9.03157 4.61331 8.17972 5.93842L7.3558 7.22007C7.13072 7.57019 7.18011 8.02986 7.47443 8.32417C7.81996 8.66971 8.38018 8.66971 8.72572 8.32418L9.70716 7.34273C9.8947 7.1552 10.1491 7.04984 10.4143 7.04984H11.2137C11.7844 7.04984 12.1291 6.41844 11.8205 5.93837Z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default Icon;
