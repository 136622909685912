// import styles from "./styles.module.css";

import { ComponentProps, ReactNode } from "react";

import { Container, Footer, NavBar } from "@booking/components";
import { ENV_VARS } from "@booking/utils/env-vars";

type PageWrapperProps = Pick<ComponentProps<typeof NavBar>, "logoHref"> & { children: ReactNode };

const PageWrapper = ({ logoHref, children }: PageWrapperProps) => {
  return (
    <div className="min-h-full flex flex-col items-stretch">
      <NavBar logoHref={logoHref} />
      <Container className="md:pt-6 grow">{children}</Container>
      <Footer />
      {!ENV_VARS.IS_PROD && (
        <div className="fixed bottom-0 right-0 bg-black text-white px-2 text-xs leading-5">
          {/* eslint-disable lingui/no-unlocalized-strings */}
          <div className="sm:hidden">xs</div>
          <div className="hidden sm:inline md:hidden">sm</div>
          <div className="hidden md:inline lg:hidden">md</div>
          <div className="hidden lg:inline xl:hidden">lg</div>
          <div className="hidden xl:inline 2xl:hidden">xl</div>
          <div className="hidden 2xl:inline 3xl:hidden">2xl</div>
          {/* eslint-enable lingui/no-unlocalized-strings */}
        </div>
      )}
    </div>
  );
};

export default PageWrapper;
