import * as Sentry from "@sentry/react";

import { IdentifyFn, ResetFn } from "@kavval/analytics";

import { ENV_VARS } from "@booking/utils/env-vars";

const enabled = ENV_VARS.KAVVAL_ENV === "production" && typeof window !== "undefined";

const identify: IdentifyFn = (user) => {
  if (!enabled) return;

  Sentry.getCurrentScope().setUser({
    id: user.id,
    uid: user.uid,
    email: user.email || undefined,
    pseudo: user.pseudo,
  });
};

const resetScope = () => {
  if (!enabled) return;

  Sentry.getCurrentScope().clear();
};

const reset: ResetFn = () => {
  if (!enabled) return;

  resetScope();
};

resetScope();

export default {
  identify,
  reset,
};
