import { useEffect, useState } from "react";

import { onAuthStateChanged } from "@booking/lib/firebase";
import useSaveProfile from "@booking/lib/hooks/useSaveProfile";

const useAuthState = ({ onChange }: { onChange: () => void }) => {
  const [newsletterOptin, setNewsletterOptin] = useState<boolean | undefined>();
  const saveProfile = useSaveProfile();

  useEffect(() => {
    return onAuthStateChanged((user: unknown) => {
      if (user && typeof newsletterOptin === "boolean") {
        // Voir SignUpForm.js pour plus d'infos sur "newsletterOptin"
        saveProfile({
          newsletter: newsletterOptin,
        }).then(() => onChange());
      } else {
        onChange();
      }
    });
  }, [onChange, newsletterOptin, saveProfile]);

  return { setNewsletterOptin };
};

export default useAuthState;
