/* eslint-disable no-irregular-whitespace */
import React, { useCallback, useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import CheckIcon from "@material-symbols/svg-300/outlined/check_circle.svg";
import { mapValues } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import { PartialButNonNullable } from "@kavval/ts-utils";

import {
  GenericNotFoundError,
  LoadingCentered,
  PageTitle,
  PageWrapper,
  ShoppingCart,
  ShoppingCartLayout,
} from "@booking/components";
import analytics from "@booking/lib/analytics";
import { trpc } from "@booking/lib/trpc";
import useAuthState from "@booking/pages/Booking/Auth/useAuthState";
import NotFoundPage from "@booking/pages/NotFound";
import { ENV_VARS } from "@booking/utils/env-vars";

const AuthForm = React.lazy(() => import("@booking/pages/Booking/Auth/AuthForm"));
const ProfileForm = React.lazy(() => import("@booking/pages/WaitingList/ProfileForm"));

const Booking = () => {
  const { raceEditionId } = useParams();
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    isLoading: isRaceEditionLoading,
    error: raceEditionError,
    data: raceEdition,
    refetch: refetchTicket,
  } = trpc.getRaceEditionById.useQuery(raceEditionId!);

  const {
    isLoading: isProfileLoading,
    error: profileError,
    data: user,
    refetch: refetchProfile,
  } = trpc.getProfile.useQuery();

  const loading = isProfileLoading || isRaceEditionLoading;

  const { setNewsletterOptin } = useAuthState({ onChange: refetchProfile });

  useEffect(() => {
    if (user) {
      analytics.identify(user, true);
    }
  }, [user]);

  const navigate = useNavigate();

  useEffect(() => {
    if (raceEdition?.soldOnKavval) {
      return navigate(`/event/${raceEdition.race.event.shortId}`);
    }
  }, [raceEdition?.soldOnKavval, navigate, raceEdition?.race.event.shortId]);

  const onSuccess = useCallback(() => {
    setShowSuccess(true);
  }, []);

  if (loading) {
    return (
      <PageWrapper>
        <LoadingCentered />
      </PageWrapper>
    );
  }

  if (raceEditionError || profileError) {
    if (raceEditionError?.data?.code === "NOT_FOUND") {
      return <NotFoundPage pageType="book/waiting-list/not-found" />;
    }

    return (
      <PageWrapper>
        <GenericNotFoundError />
      </PageWrapper>
    );
  }

  const event = raceEdition.race.event;

  // TODO : Checker si des offres sont disponibles et afficher un message si c'est le cas
  // Quelqu'un pourrait arriver sur cette page une fois les offres ouvertes (bookmark, lien partagé, etc)

  const cart = {
    items: [
      {
        offer: {
          raceEdition,
        },
        options: [],
      },
    ],
  };

  if (showSuccess) {
    return (
      <PageWrapper logoHref={`https://www.${ENV_VARS.KAVVAL_DOMAIN}${event.href}`}>
        <ShoppingCartLayout rightContent={<ShoppingCart cart={cart} />}>
          <div className="flex flex-col items-center gap-4 text-lg text-center leading-tight py-6 lg:py-20">
            <CheckIcon className="fill-electric-blue text-5xl" />
            <p className="font-bold">
              <Trans id="page.waitingList.success.message.p1">
                Tu es bien inscrit à la liste d'attente !
              </Trans>
            </p>
            <p>
              <Trans id="page.waitingList.success.message.p2">
                Nous reviendrons vers toi dès que possible.
              </Trans>
            </p>
            <a className="button button-primary mt-6" href={event.href}>
              <Trans id="page.waitingList.success.button">Revenir à l'évènement</Trans>
            </a>
          </div>
        </ShoppingCartLayout>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper logoHref={`https://www.${ENV_VARS.KAVVAL_DOMAIN}${event.href}`}>
      <ShoppingCartLayout rightContent={<ShoppingCart cart={cart} />}>
        <PageTitle
          title={t({ id: "page.waitingList.title", message: "Inscription sur la liste d'attente" })}
          subtitle={
            !user
              ? t({
                  id: "page.waitingList.subtitle.beforeAuth",
                  message: "Connecte-toi ou crée un compte Finishers pour continuer",
                })
              : t({
                  id: "page.waitingList.subtitle.afterAuth",
                  message: "Qui es-tu ?",
                })
          }
        />
        {!user ? (
          <AuthForm setNewsletterOptin={setNewsletterOptin} />
        ) : (
          <ProfileForm
            // TODO mieux type "genre" côté graphql
            defaultValue={
              mapValues(user, (v) => (v === null ? undefined : v)) as PartialButNonNullable<
                typeof user & { genre: "m" | "f" | null }
              >
            }
            showNewsletter={!user.newsletter}
            raceEditionId={raceEditionId!}
            onSuccess={onSuccess}
          />
        )}
      </ShoppingCartLayout>
    </PageWrapper>
  );
};

export default Booking;
