import { type Locale } from "@kavval/lingui-config/locales";

type FormatReturn = (params: { locale: Locale; shouldDisplayUnit: boolean }) => string;
type FormatWith = (distance: number) => FormatReturn;

type Format = (params: {
  distance: number;
  unit: "m" | "km";
  maximumFractionDigits?: number;
}) => FormatReturn;
const format: Format =
  ({ distance, unit, maximumFractionDigits }) =>
  ({ locale, shouldDisplayUnit }) => {
    const unitDisplayed = shouldDisplayUnit ? `\u2009${unit}` : "";

    return `${new Intl.NumberFormat(locale, { maximumFractionDigits }).format(
      distance
    )}${unitDisplayed}`;
  };

type FormatWithKilometers = FormatWith;
const formatWithKilometers: FormatWithKilometers = (distance) => {
  const metersDistance = distance === 21097 ? 21097.5 : distance;
  const kilometersDistance = metersDistance / 1000;

  return format({
    distance: kilometersDistance,
    unit: "km",
    maximumFractionDigits: 4,
  });
};

type FormatWithMeters = FormatWith;
const formatWithMeters: FormatWithMeters = (distance) => {
  return format({
    distance,
    unit: "m",
  });
};

type FormatDistance = (params: {
  locale: Locale;
  distance: number;
  shouldDisplayUnit?: boolean;
}) => string;
export const formatDistance: FormatDistance = ({ locale, distance, shouldDisplayUnit = true }) => {
  const format = distance < 1000 ? formatWithMeters : formatWithKilometers;

  return format(distance)({ locale, shouldDisplayUnit });
};
