import { useLingui } from "@lingui/react";
import clsx from "clsx";
import { upperFirst } from "lodash";

import { EventStatusEnum } from "@kavval/constants/EventStatus";
import { formatDateRange, getApproxDate } from "@kavval/formatters";
import { type Locale } from "@kavval/lingui-config/locales";

import EventStatusChip from "../EventStatusChip";

type EventDateProps = {
  className?: string;
  endDate: string | null;
  startDate: string | null;
  isRescheduled?: boolean;
  status: EventStatusEnum;
};

const EventDate = ({ className, endDate, isRescheduled, startDate, status }: EventDateProps) => {
  const { i18n } = useLingui();
  return (
    <div className={clsx("flex flex-row items-center", className)}>
      {startDate &&
        (status !== EventStatusEnum.ToBeAnnonced
          ? upperFirst(
              formatDateRange(i18n.locale as Locale, startDate, endDate || startDate, {
                monthOnly: status !== "confirmed",
              }) as string
            )
          : getApproxDate({
              date: startDate,
              // @ts-ignore
              i18n,
            }))}
      {status && <EventStatusChip status={status} isRescheduled={isRescheduled} />}
    </div>
  );
};

EventDate.displayName = "EventDate";
export default EventDate;
