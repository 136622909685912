/* eslint react/jsx-no-target-blank:0 */

import { CardBrandIcon } from "@booking/components";

const CardPaymentLogos = () => {
  return (
    <div className="flex flex-row items-center ">
      <CardBrandIcon
        className="mr-2 md:mr-3 max-w-[28px] max-h-[19px] md:max-w-[47px] md:max-h-[32px]"
        // eslint-disable-next-line lingui/no-unlocalized-strings
        brand="Carte Bleue"
      />
      <CardBrandIcon
        className="mr-2 md:mr-3 max-w-[28px] max-h-[19px] md:max-w-[47px] md:max-h-[32px]"
        // eslint-disable-next-line lingui/no-unlocalized-strings
        brand="Visa"
      />
      <CardBrandIcon
        className="mr-2 md:mr-3 max-w-[28px] max-h-[19px] md:max-w-[47px] md:max-h-[32px]"
        // eslint-disable-next-line lingui/no-unlocalized-strings
        brand="MasterCard"
      />
      <CardBrandIcon
        className="max-w-[28px] max-h-[19px] md:max-w-[47px] md:max-h-[32px]"
        // eslint-disable-next-line lingui/no-unlocalized-strings
        brand="American Express"
      />
    </div>
  );
};

export default CardPaymentLogos;
