import clsx from "clsx";

const Container = ({ children, className }) => {
  return (
    <div
      className={clsx(
        "w-full max-w-screen-xl mx-auto px-4 sm:px-8 md:px-8 lg:px-10 xl:px-24",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;
