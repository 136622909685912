import {
  assetFaviconDevAdmin,
  assetFaviconDevDefault,
  assetFaviconProdAdmin,
  assetFaviconProdDefault,
  assetFaviconStagingAdmin,
  assetFaviconStagingDefault,
  colorElectricBlue,
} from "@kavval/design-tokens";
import { createCloudinaryURL, rgbStringToHex } from "@kavval/formatters";

const color = rgbStringToHex(colorElectricBlue);

let NODE_ENV: string;
try {
  // @ts-ignore
  NODE_ENV = process.env.NODE_ENV;
} catch (e) {
  try {
    // @ts-ignore
    NODE_ENV = import.meta.env.NODE_ENV;
  } catch (e) {
    NODE_ENV = "production";
  }
}

const favicons = {
  production: {
    default: assetFaviconProdDefault,
    admin: assetFaviconProdAdmin,
  },
  staging: {
    default: assetFaviconStagingDefault,
    admin: assetFaviconStagingAdmin,
  },
  dev: {
    default: assetFaviconDevDefault,
    admin: assetFaviconDevAdmin,
  },
};

const HeadAppMeta = ({
  kavvalEnv,
  app = "default",
}: {
  kavvalEnv: string;
  app?: "default" | "admin";
}) => {
  const env =
    NODE_ENV === "development" ? "dev" : kavvalEnv === "production" ? "production" : "staging";

  return (
    <>
      <link
        rel="icon"
        type="image/svg+xml"
        href={createCloudinaryURL(favicons[env][app], ["f_svg"]) as string}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={createCloudinaryURL(favicons[env][app], ["w_180", "f_png"]) as string}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={createCloudinaryURL(favicons[env][app], ["w_32", "f_png"]) as string}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={createCloudinaryURL(favicons[env][app], ["w_16", "f_png"]) as string}
      />
      <meta name="apple-mobile-web-app-title" content="Finishers" />
      <meta name="application-name" content="Finishers" />
      <meta name="msapplication-TileColor" content={color} />
      <meta name="theme-color" content={color} />
    </>
  );
};

export default HeadAppMeta;
