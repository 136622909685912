export enum FfaLabelEnum {
  International = "international",
  National = "national",
  Regional = "regional",
  Montagne = "montagne",
}

const values = Object.values(FfaLabelEnum);

export default values;
