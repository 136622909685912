import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      d="M20.055 1l.485.009c8.694.287 15.64 7.462 15.61 16.241v.195c-.1 5.236-2.72 10.393-7.067 14.995a40.141 40.141 0 01-7.137 5.894l.019-.014-.092.077a3.213 3.213 0 01-3.501.16l-.245-.16-.115-.09-.785-.528a39.444 39.444 0 01-8.614-8.087l-.601-.785A20.399 20.399 0 013.85 17.2l.009-.541C4.145 7.938 11.297.97 20.055 1zm-.01 2.883l-.457.006c-7.12.218-12.84 6.064-12.864 13.26l.036.68a17.486 17.486 0 003.543 9.337 36.582 36.582 0 009.314 8.748l.157.118c.13.113.322.113.452 0l.16-.12a37.233 37.233 0 006.619-5.463c3.889-4.117 6.187-8.641 6.27-13.032l-.006-.63c-.216-7.143-6.045-12.88-13.224-12.904zM20 11.263c3.398 0 6.152 2.762 6.152 6.171 0 3.408-2.754 6.171-6.152 6.171s-6.153-2.763-6.153-6.171c0-3.409 2.755-6.172 6.153-6.172zm0 2.883a3.283 3.283 0 00-3.279 3.288A3.283 3.283 0 0020 20.722a3.283 3.283 0 003.278-3.288A3.283 3.283 0 0020 14.146z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default Icon;
