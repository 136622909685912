import sumBy from "lodash/sumBy";

import { PageFn, TrackFn } from "..";
import { TrackEventOptions } from "../types";

const setup = () => {};

const track: TrackFn = (event, ...args) => {
  if (event === "add_to_cart") {
    // TODO : améliorer l'inférence de type
    const { items } = args[0] as TrackEventOptions["add_to_cart"][0];

    // @ts-ignore
    return window.wisepops("goal", "addToCart", sumBy(items, "price"));
  }

  if (event === "checkout_progress") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["checkout_progress"][0];
    const { items } = properties;

    // @ts-ignore
    return window.wisepops("goal", "viewBasket", sumBy(items, "price"));
  }

  if (event === "purchase") {
    // TODO : améliorer l'inférence de type
    const { items } = args[0] as TrackEventOptions["purchase"][0];

    // @ts-ignore
    return window.wisepops("goal", "trackTransaction", sumBy(items, "price"));
  }
};

const page: PageFn = (properties) => {
  // @ts-ignore
  window.wisepops("pageview");
};

export default {
  setup,
  track,
  page,
};
