import { forwardRef, MouseEvent, useRef } from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import Label from "../Label";
import { cn } from "../lib/utils";

const SwitchComponent = forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer bg-gray-500 inline-flex p-0 h-5 w-9 shrink-0 cursor-pointer justify-start items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0"
      )}
    />
  </SwitchPrimitives.Root>
));
SwitchComponent.displayName = SwitchPrimitives.Root.displayName;

type SwitchProps = Omit<
  React.ComponentProps<typeof SwitchComponent>,
  "checked" | "asChild" | "value" | "onChange"
> & {
  checked: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
};
const Switch = ({ label, disabled, checked, onChange, ...props }: SwitchProps) => {
  const switchRef = useRef<HTMLButtonElement>(null);
  const onClick = (e: MouseEvent) => {
    if (switchRef.current && e.target === switchRef.current) {
      switchRef.current.click();
    }
  };
  return (
    <div
      className={cn("inline-flex flex-row items-center mr-5", disabled && "pointer-events-none")}
    >
      <Label className="font-normal" onClick={onClick}>
        <span className="inline-flex flex-row items-center cursor-pointer gap-3">
          <SwitchComponent
            ref={switchRef}
            checked={checked}
            onCheckedChange={onChange}
            {...props}
            disabled={disabled}
          />
          {label && <span>{label}</span>}
        </span>
      </Label>
    </div>
  );
};

export default Switch;
