"use client";

import { FC } from "react";
import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import isArray from "lodash/isArray";

const emojiRegex = /^([^a-z]+) /i;

export const splitEmojiText = (str: string) => {
  if (!str) return { text: str };

  const match = str.match(emojiRegex);

  if (!match) {
    return { text: str };
  }

  return { emoji: match[1] || "🏷️", text: str.replace(emojiRegex, "").trim() };
};

const translate = (i18n: I18n, tag: string, emojiOnly: boolean, textOnly: boolean) => {
  const noExtract = i18n._.bind(i18n);

  const translation = noExtract(`tags.${tag}`);

  if (textOnly || emojiOnly) {
    const { emoji, text } = splitEmojiText(translation);

    if (textOnly) {
      return text;
    }
    if (emojiOnly) {
      return <span title={text}>{emoji}</span>;
    }
  }

  return translation;
};

interface RaceTagProps {
  tag: string | string[];
  separator?: string;
  emojiOnly?: boolean;
  textOnly?: boolean;
}

const RaceTag: FC<RaceTagProps> = ({
  tag,
  separator = ", ",
  emojiOnly = false,
  textOnly = false,
}) => {
  const { i18n } = useLingui();

  if (isArray(tag)) {
    return <>{tag.map((d) => translate(i18n, d, emojiOnly, textOnly)).join(separator)}</>;
  }

  return <>{translate(i18n, tag, emojiOnly, textOnly)}</>;
};

export default RaceTag;
