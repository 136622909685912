import { ComponentProps } from "react";

import { SponsorsGrid } from "@kavval/ui";

const SponsorsSection = ({ sponsors }: Pick<ComponentProps<typeof SponsorsGrid>, "sponsors">) => {
  return (
    <div className="py-14">
      <SponsorsGrid sponsors={sponsors} />
    </div>
  );
};

export default SponsorsSection;
