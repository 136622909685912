import * as React from "react";
import { t, Trans } from "@lingui/macro";
import ChevronLeftIcon from "@material-symbols/svg-600/rounded/chevron_left.svg";
import ChevronRightIcon from "@material-symbols/svg-600/rounded/chevron_right.svg";
import DotsHorizontalIcon from "@material-symbols/svg-600/rounded/more_horiz.svg";

import { cn } from "@kavval/ui/lib/utils";

const PaginationNav = ({ className, ...props }: React.ComponentProps<"nav">) => (
  <nav
    role="navigation"
    aria-label={t({ id: "generic.pagination.label", message: "pagination" })}
    className={cn("mx-auto flex w-full justify-center", className)}
    {...props}
  />
);
PaginationNav.displayName = "PaginationNav";

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<"ul">>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn("flex flex-row items-center gap-1", className)} {...props} />
  )
);
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<"li">>(
  ({ className, ...props }, ref) => <li ref={ref} className={cn(className)} {...props} />
);
PaginationItem.displayName = "PaginationItem";

const commonClasses =
  "flex items-center justify-center h-9 w-9 rounded-full hover:bg-gray-100 no-underline";

type PaginationLinkProps = {
  isActive?: boolean;
  isDisabled?: boolean;
} & React.ComponentProps<"a">;

const PaginationLink = ({
  isActive,
  isDisabled,
  className,
  children,
  ...props
}: PaginationLinkProps) => {
  return (
    <a
      aria-current={isActive ? "page" : undefined}
      className={cn(
        commonClasses,
        isDisabled && "pointer-events-none opacity-40",
        isActive && "bg-primary text-white hover:bg-blue-600",
        className
      )}
      {...props}
    >
      {children}
    </a>
  );
};
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = () => (
  <>
    <ChevronLeftIcon className="h-6 w-6" />
    <span className="sr-only">
      <Trans id="generic.pagination.previous">Précédent</Trans>
    </span>
  </>
);
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = () => (
  <>
    <span className="sr-only">
      <Trans id="generic.pagination.next">Suivant</Trans>
    </span>
    <ChevronRightIcon className="h-6 w-6" />
  </>
);
PaginationNext.displayName = "PaginationNext";

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    <DotsHorizontalIcon className="h-6 w-6" />
    <span className="sr-only">
      <Trans id="generic.pagination.more">Plus de pages</Trans>
    </span>
  </span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

type PaginationProps = {
  limit?: number;
  current: number;
  total: number;
  renderItem: (args: {
    page: number;
    props: PaginationLinkProps;
    children: React.ReactNode;
  }) => React.ReactNode;
};

const createRangeArray = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, i) => i + start);
};
const Pagination = ({ current, total, limit = 4, renderItem }: PaginationProps) => {
  const paginateArray = React.useMemo(() => {
    if (current < limit) {
      // from 0 to limit (4), add first 4 items
      return createRangeArray(1, limit);
    } else if (current === limit) {
      // if limit (4), add first 4 items and 1 more item after
      return createRangeArray(1, limit + 1);
    } else if (current > total - limit + 1) {
      // from the end to (end - limit), add last 4 items
      return createRangeArray(total - limit + 1, total);
    } else if (current === total - limit + 1) {
      // if (end - limit) add last 4 items and 1 more item before
      return createRangeArray(total - limit, total);
    } else {
      // else add only 1 item before and after the current value
      return createRangeArray(current - 1, current + 1);
    }
  }, [current, limit, total]);

  if (total <= 1) {
    return null;
  }

  return (
    <PaginationNav>
      <PaginationContent>
        <PaginationItem>
          {renderItem({
            page: current - 1,
            props: { isDisabled: current === 1 },
            children: <PaginationPrevious />,
          })}
        </PaginationItem>

        {current > limit && (
          <>
            <PaginationItem>
              {renderItem({ page: 1, props: { isActive: current === 1 }, children: "1" })}
            </PaginationItem>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          </>
        )}
        {paginateArray.map((value) => (
          <PaginationItem key={value}>
            {renderItem({ page: value, props: { isActive: current === value }, children: value })}
          </PaginationItem>
        ))}
        {paginateArray[paginateArray.length - 1] !== total && (
          <>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
            <PaginationItem>
              {renderItem({ page: total, props: { isActive: current === total }, children: total })}
            </PaginationItem>
          </>
        )}

        <PaginationItem>
          {renderItem({
            props: { isDisabled: current === total },
            page: current + 1,
            children: <PaginationNext />,
          })}
        </PaginationItem>
      </PaginationContent>
    </PaginationNav>
  );
};

export { Pagination, PaginationLink };
