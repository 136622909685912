import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = ({ color, ...props }) => (
  <SvgIcon width="21" height="21" viewBox="0 0 21 21" {...props}>
    <path d="M10.19 20.25a10 10 0 100-20 10 10 0 000 20z" fill={color || "#3C5A9A"} />
    <path
      d="M13.44 3.32h-2.22c-1.31 0-2.77.55-2.77 2.46v2.01H6.93v2.42h1.56v6.97h2.88v-7.01h1.9l.18-2.38h-2.13V6.42c0-.75.79-.7.84-.7h1.28v-2.4z"
      fill="#fff"
    />
  </SvgIcon>
);

export default Icon;
