import { forwardRef, HTMLProps } from "react";
import clsx from "clsx";

import FormGroup from "../FormGroup";

interface NativeSelectProps extends HTMLProps<HTMLSelectElement> {
  label?: string;
  id?: string;
  name?: string;
  choices: { value: string | number; label: string | number }[];
  fullWidth?: boolean;
  required?: boolean;
  error?: string | boolean | null;
  className?: string;
  rightLabel?: string;
  helperText?: string;
  placeholder?: string;
  allowEmpty?: boolean;
}

const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
  (
    {
      label,
      id,
      name,
      choices,
      fullWidth,
      required,
      error,
      className,
      placeholder,
      rightLabel,
      helperText,
      allowEmpty = true,
      ...props
    },
    ref
  ) => {
    const otherProps = {} as Pick<HTMLSelectElement, "required">;

    if (required) {
      // @ts-ignore
      otherProps["aria-required"] = "true";
      otherProps.required = true;
    }

    if (error) {
      // @ts-ignore
      otherProps["aria-invalid"] = "true";
    }

    const input = (
      <select
        ref={ref}
        id={id || name}
        name={name}
        {...props}
        {...otherProps}
        className={clsx("form-select", fullWidth && "block w-full", className)}
      >
        {allowEmpty && <option value="">{placeholder}</option>}
        {choices.map((choice) => {
          const value = choice.value || choice.label;
          return (
            <option key={value} value={value}>
              {choice.label}
            </option>
          );
        })}
      </select>
    );

    return label ? (
      <FormGroup
        label={label}
        id={id || name}
        rightLabel={rightLabel}
        error={error}
        helperText={helperText}
      >
        {input}
      </FormGroup>
    ) : (
      input
    );
  }
);

NativeSelect.displayName = "NativeSelect";

NativeSelect.defaultProps = {};

export default NativeSelect;
