/* eslint react/jsx-no-target-blank:0 */

import logoAmex from "./logo-amex.png";
import logoCb from "./logo-cb.png";
import logoGeneric from "./logo-generic.png";
import logoMastercard from "./logo-mastercard.png";
import logoVisa from "./logo-visa.png";

// American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown
const brandLogos = {
  "american express": logoAmex,
  mastercard: logoMastercard,
  visa: logoVisa,
  "carte bleue": logoCb,
};

const CardBrandIcon = ({ brand, ...props }) => {
  const image = brandLogos[(brand || "").toLowerCase()] || logoGeneric;
  return <img {...props} src={image} alt={brand} />;
};

export default CardBrandIcon;
