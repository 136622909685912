import { FirebaseApp, initializeApp } from "firebase/app";
import {
  ActionCodeSettings,
  Auth,
  browserLocalPersistence,
  CompleteFn,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
  ErrorFn,
  fetchSignInMethodsForEmail as fetchSignInMethodsForEmailFirebase,
  User as FirebaseUser,
  getAuth as getFirebaseAuth,
  NextOrObserver,
  onAuthStateChanged as onAuthStateChangedFirebase,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  setPersistence,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  useDeviceLanguage,
} from "firebase/auth";

import { ENV_VARS } from "@booking/utils/env-vars";

const config = {
  apiKey: ENV_VARS.FIREBASE_API_KEY,
  authDomain: ENV_VARS.FIREBASE_AUTH_DOMAIN,
  projectId: ENV_VARS.FIREBASE_PROJECT_ID,
  storageBucket: ENV_VARS.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV_VARS.FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV_VARS.FIREBASE_APP_ID,
};

let firebaseApp: FirebaseApp | undefined;
let firebaseAuth: Auth;

export const getApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(config);
  }

  return firebaseApp;
};

export const getAuth = () => {
  if (!firebaseAuth) {
    firebaseAuth = getFirebaseAuth(getApp());

    setPersistence(firebaseAuth, browserLocalPersistence);
    // eslint-disable-next-line
    useDeviceLanguage(firebaseAuth);
  }

  return firebaseAuth;
};

export const getCurrentUser = async () => {
  const auth = getAuth();
  if (auth.currentUser) return auth.currentUser;

  return new Promise<FirebaseUser | null>((resolve, reject) => {
    const unsubscribe = onAuthStateChangedFirebase(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      (error) => {
        unsubscribe();
        reject(error);
      }
    );
  });
};

export const createUserWithEmailAndPassword = (email: string, password: string) => {
  return createUserWithEmailAndPasswordFirebase(getAuth(), email, password);
};

export const signInWithEmailAndPassword = (email: string, password: string) =>
  signInWithEmailAndPasswordFirebase(getAuth(), email, password);

export const fetchSignInMethodsForEmail = (email: string) =>
  fetchSignInMethodsForEmailFirebase(getAuth(), email);

export const onAuthStateChanged = (
  nextOrObserver: NextOrObserver<FirebaseUser>,
  errorFn?: ErrorFn,
  completeFn?: CompleteFn
) => onAuthStateChangedFirebase(getAuth(), nextOrObserver, errorFn, completeFn);

export const sendPasswordResetEmail = (email: string, actionCodeSettings?: ActionCodeSettings) =>
  sendPasswordResetEmailFirebase(getAuth(), email, actionCodeSettings);
