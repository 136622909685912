export enum EventStatusEnum {
  Confirmed = "confirmed",
  Cancelled = "cancelled",
  ToBeAnnonced = "tba",
  Rescheduled = "rescheduled",
}

const values = Object.values(EventStatusEnum);

export default values;
