import { FC, SVGProps } from "react";

const SvgIcon: FC<SVGProps<SVGSVGElement>> = ({ style, ...props }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: "1em", width: "1em", ...style }}
    {...props}
  ></svg>
);

export default SvgIcon;
