export default [
  "en",
  "fr",
  "nl",
  "de",
  "it",
  "no",
  "pt",
  "es",
  "sv",
  "da",
  "el",
  "pl",
  "hu",
  "cs",
  /*INSERT_NEW_LOCALE_HERE*/
];
