import { ComponentProps, forwardRef } from "react";

import RadioCheckboxBase from "../Radio/RadioCheckboxBase";

const Checkbox = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<typeof RadioCheckboxBase>, "type">
>((props, ref) => {
  return <RadioCheckboxBase type="checkbox" ref={ref} {...props} />;
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
