import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      d="M25 34.6c.5.5.6 1.3.2 1.9-.4.4-.8.8-1.2 1a6.6 6.6 0 01-9.3-1 1.3 1.3 0 01.2-1.9 1.3 1.3 0 011.9.2 4 4 0 002.6 1.5 4 4 0 003-.8l.7-.7a1.3 1.3 0 011.9-.2zM7 14.3v-1.1-1.4C7.8 5.6 13.6 1 20 1s12.3 4.6 13 10.8v3.8c.4 1 .8 2 1.5 2.7l.3.4v.1a7.6 7.6 0 011.4 4v.7c-.1 1.7-.7 3.4-1.9 4.8a9.1 9.1 0 01-5.8 2.8 79 79 0 01-17 0 9.3 9.3 0 01-5.9-2.9 7.7 7.7 0 01-1.7-5v-.5A7.8 7.8 0 015 18.8l.1-.1A7.3 7.3 0 007 15.2v.1-1zM20 3.7C14.7 3.7 10 7.3 9.6 12a21.9 21.9 0 000 .8v2.7l-.1.2a10 10 0 01-2.4 4.8v-.2l-.1.3a5 5 0 00-.6 1.8v.8a5 5 0 001 3.2 6.6 6.6 0 004.2 2 76.5 76.5 0 0016.6 0c1.6 0 3-.8 4-1.9a5 5 0 001.2-3.4v-.3a5 5 0 00-.8-2.5l-.2-.2a10.1 10.1 0 01-2-3.8v-.6l-.1-.1V13a12.7 12.7 0 000-1c-.5-4.8-5.2-8.4-10.3-8.4z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default Icon;
