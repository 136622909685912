import { createElement, FC, FunctionComponent, memo } from "react";
import clsx from "clsx";

import styles from "./style.module.css";

interface RawHtmlProps {
  innerHtml: string;
  // eslint-disable-next-line
  node?: FunctionComponent<any> | string;
  className?: string;
}

const RawHtml: FC<RawHtmlProps> = ({ innerHtml, node = "span", className, ...props }) =>
  innerHtml
    ? createElement(node, {
        ...props,
        className: clsx(className, styles.RawHtml),
        dangerouslySetInnerHTML: {
          __html: innerHtml,
        },
      })
    : null;

export default memo(RawHtml);
