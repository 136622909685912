import { CircularProgress } from "@mui/material";
import clsx from "clsx";

const LoadingCentered = ({ className }: { className?: string }) => {
  return (
    <div className={clsx("flex flex-row items-center justify-center py-24", className)}>
      <CircularProgress />
    </div>
  );
};

export default LoadingCentered;
