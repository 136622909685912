import { AlgoliaRaceRecord, EventPreviewData } from "@kavval/constants";
import { Locale } from "@kavval/lingui-config/locales";

type Event = AlgoliaRaceRecord | EventPreviewData;

export const getLocaleAttribute = <T extends keyof Event>(
  locale: Locale,
  event: Event,
  attribute: T
) => {
  if (locale === "fr") {
    return event[attribute];
  }

  const localeAttribute = `${attribute}_${locale}` as keyof Event;
  const fallbackAttribute = `${attribute}_en` as keyof Event;

  if (localeAttribute in event && event[localeAttribute]) {
    return event[localeAttribute] as Event[T];
  } else if (fallbackAttribute in event && event[fallbackAttribute]) {
    return event[fallbackAttribute] as Event[T];
  } else {
    return event[attribute];
  }
};
