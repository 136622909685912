import { FC, HTMLProps, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

export interface LabelProps extends Omit<HTMLProps<HTMLLabelElement>, "label"> {
  label?: ReactNode;
  className?: string;
  rightLabel?: ReactNode;
  noMargin?: boolean;
}

const Label: FC<PropsWithChildren<LabelProps>> = ({
  label,
  children,
  className,
  rightLabel,
  noMargin,
  ...props
}) => {
  return (
    <label
      className={clsx("form-label flex flex-row justify-between ", noMargin && "!mb-0", className)}
      {...props}
    >
      <span className="grow">{label || children}</span>
      <span>{rightLabel}</span>
    </label>
  );
};

export default Label;
