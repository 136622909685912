type FormatWithHours = (time: number) => string;
const formatWithHours: FormatWithHours = (time) => {
  const hour = `${Math.floor(time / 60)}h`;
  const distanceModulo = time % 60;
  const minutes = distanceModulo > 0 ? `${distanceModulo}`.padStart(2, "0") : "";

  return `${hour}${minutes}`;
};

type FormatTime = (params: { time: number }) => string;
/**
 * @param options.time Duration in minutes
 */
export const formatTime: FormatTime = ({ time }) => {
  return time < 60 ? `${time}\u2009min` : formatWithHours(time);
};
