import { createElement, memo } from "react";
import clsx from "clsx";
import T from "prop-types";

import styles from "./style.module.css";

type RawHtmlProps = {
  innerHtml: string;
  node?: string;
  className?: string;
};

const RawHtml = ({ innerHtml, node = "span", className, ...props }: RawHtmlProps) =>
  innerHtml
    ? createElement(node, {
        ...props,
        className: clsx(className, styles.RawHtml),
        dangerouslySetInnerHTML: {
          __html: innerHtml,
        },
      })
    : null;

RawHtml.propTypes = {
  innerHtml: T.string,
  node: T.string,
};

export default memo(RawHtml);
