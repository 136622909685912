export enum OfferErrorCodeEnum {
  SOLD_OUT = "OFFER_SOLD_OUT",
  EXPIRED = "OFFER_EXPIRED",
  NOT_OPENED = "OFFER_NOT_OPENED",
  UNAVAILABLE = "OFFER_UNAVAILABLE",
}

export enum OptionErrorCodeEnum {
  SOLD_OUT = "OPTION_SOLD_OUT",
  EXPIRED = "OPTION_EXPIRED",
  NOT_OPENED = "OPTION_NOT_OPENED",
  UNAVAILABLE = "OPTION_UNAVAILABLE",
}

type Context<T extends OfferErrorCodeEnum | OptionErrorCodeEnum> = T extends OfferErrorCodeEnum
  ? { offerId: string }
  : { optionId: string };

export class OrderError<
  ErrorCode extends OfferErrorCodeEnum | OptionErrorCodeEnum,
  ErrorContext extends Context<ErrorCode> = Context<ErrorCode>,
> extends Error {
  public type = "OrderError";
  public context: ErrorContext;
  public code: ErrorCode;
  // Pour graphql...
  public extensions: { context: ErrorContext; code: ErrorCode };

  constructor(code: ErrorCode, context: ErrorContext) {
    super(code);
    // Les propriétés suivantes seront sérialisées si on fait JSON.stringify(err)
    this.code = code;
    this.context = context;

    this.extensions = {
      code: code,
      context: context,
    };
  }
}

export class OfferSoldOutError extends OrderError<OfferErrorCodeEnum> {
  constructor(context: { offerId: string }) {
    super(OfferErrorCodeEnum.SOLD_OUT, context);
  }
}

export class OfferExpiredError extends OrderError<OfferErrorCodeEnum> {
  constructor(context: { offerId: string }) {
    super(OfferErrorCodeEnum.EXPIRED, context);
  }
}

export class OfferNotOpenedError extends OrderError<OfferErrorCodeEnum> {
  constructor(context: { offerId: string }) {
    super(OfferErrorCodeEnum.NOT_OPENED, context);
  }
}

export class OfferUnavailableError extends OrderError<OfferErrorCodeEnum> {
  constructor(context: { offerId: string }) {
    super(OfferErrorCodeEnum.UNAVAILABLE, context);
  }
}

export class OptionSoldOutError extends OrderError<OptionErrorCodeEnum> {
  constructor(context: { optionId: string }) {
    super(OptionErrorCodeEnum.SOLD_OUT, context);
  }
}

export class OptionExpiredError extends OrderError<OptionErrorCodeEnum> {
  constructor(context: { optionId: string }) {
    super(OptionErrorCodeEnum.EXPIRED, context);
  }
}

export class OptionNotOpenedError extends OrderError<OptionErrorCodeEnum> {
  constructor(context: { optionId: string }) {
    super(OptionErrorCodeEnum.NOT_OPENED, context);
  }
}

export class OptionUnavailableError extends OrderError<OptionErrorCodeEnum> {
  constructor(context: { optionId: string }) {
    super(OptionErrorCodeEnum.UNAVAILABLE, context);
  }
}
