import { ActivityEnum } from "./Activity";

export enum MultiSportsDisciplineEnum {
  Aquathlon = "aquathlon",
  CrossTriathlon = "cross_triathlon",
  Cyclathlon = "cyclathlon",
  Duathlon = "duathlon",
  SwimRun = "swimrun",
  SwimBike = "swimbike",
  Triathlon = "triathlon",
  Multisports = "multisports",
}

/**
 * @description GenericDiscipline est l'union des disciplines multisports et des disciplines simples (activités)
 */
export type GenericDiscipline = MultiSportsDisciplineEnum | ActivityEnum;

const multisports = Object.values(MultiSportsDisciplineEnum);

export const DisciplineDefaultPicture: Record<GenericDiscipline, string> = {
  [ActivityEnum.Trail]: "app/disciplines/csm_TrailRunning_B174783_by.davidbirri_ca9323338d_qrb4ik",
  [ActivityEnum.Road]: "app/disciplines/fotolia_118553864-449x300_pa0xpn",
  [ActivityEnum.Walking]: "app/disciplines/nodic_phsp0o",
  [ActivityEnum.ObstacleRace]: "app/disciplines/AdobeStock_142449922_zknaw6_lfzkwq",
  [ActivityEnum.Cycling]: "app/disciplines/cycling_llw2ub",
  [ActivityEnum.GravelBiking]: "app/disciplines/gravel_tp9mhl",
  [ActivityEnum.Nordic]: "app/disciplines/nodic_phsp0o",
  [ActivityEnum.MountainBiking]: "app/disciplines/vtt_pqampi",
  [ActivityEnum.BikeAndRun]: "",
  [ActivityEnum.CycloCross]: "",
  [ActivityEnum.Swimming]: "",
  [ActivityEnum.Cross]: "",
  [ActivityEnum.Canoe]: "",
  [MultiSportsDisciplineEnum.Duathlon]: "app/disciplines/duathlon_jtonsr",
  [MultiSportsDisciplineEnum.Triathlon]: "app/disciplines/triathlon_bkubvx",
  [MultiSportsDisciplineEnum.Aquathlon]: "",
  [MultiSportsDisciplineEnum.CrossTriathlon]: "",
  [MultiSportsDisciplineEnum.Cyclathlon]: "",
  [MultiSportsDisciplineEnum.SwimRun]: "",
  [MultiSportsDisciplineEnum.SwimBike]: "",
  [MultiSportsDisciplineEnum.Multisports]: "",
};

export const isMultiSports = (value: GenericDiscipline): value is MultiSportsDisciplineEnum => {
  // @ts-ignore
  return multisports.includes(value);
};

export const DisciplineValues = [
  ...Object.values(ActivityEnum),
  ...Object.values(MultiSportsDisciplineEnum),
] as GenericDiscipline[];
