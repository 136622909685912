const PageTitle = ({ title, subtitle }) => {
  return (
    <div className="mb-4 md:mb-8">
      <div className="typo-h2">{title}</div>
      <div className="font-semibold text-base md:text-lg text-storm-grey !leading-tight">
        {subtitle}
      </div>
    </div>
  );
};

export default PageTitle;
