import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = ({ color, ...props }) => (
  <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
    <circle cx="24" cy="24" fill={color || "#1CB7EB"} r="24" />
    <path
      d="M36.8 15.4a8 8 0 01-3 .9 5.6 5.6 0 002.3-3.1c-1 .6-2.1 1.1-3.4 1.4a5.1 5.1 0 00-3.8-1.8c-2.9 0-5.3 2.5-5.3 5.7l.1 1.3c-4.4-.2-8.3-2.5-10.9-5.9-.5.8-.7 1.8-.7 2.9 0 2 .9 3.7 2.3 4.7-.9 0-1.7-.3-2.4-.7v.1c0 2.7 1.8 5 4.2 5.6l-1.4.2-1-.1c.7 2.3 2.6 3.9 4.9 3.9a10.2 10.2 0 01-6.5 2.4l-1.3-.1c2.3 1.6 5.1 2.6 8.1 2.6 9.7 0 15-8.6 15-16.1v-.7c1.2-1 2.1-2 2.8-3.2z"
      fill="#FFF"
    />
  </SvgIcon>
);

export default Icon;
