import { FC } from "react";
import { Trans } from "@lingui/macro";
import clsx from "clsx";

import { EventStatusEnum } from "@kavval/constants";

import styles from "./styles.module.css";

interface EventStatusChipProps {
  status: EventStatusEnum;
  isRescheduled?: boolean | null;
}

const EventStatusChip: FC<EventStatusChipProps> = ({ status, isRescheduled }) => {
  if (status === "confirmed") {
    return (
      <span className={clsx(styles.Chip, styles.Confirmed)}>
        <span className={styles.StatusText}>
          {<Trans id="generic.raceStatus.confirmedShort">Date confirmée</Trans>}
        </span>
      </span>
    );
  } else if (status === "cancelled") {
    return (
      <span className={clsx(styles.Chip, styles.Cancelled)}>
        {<Trans id="generic.raceStatus.cancelledShort">Annulée</Trans>}
      </span>
    );
  } else if (isRescheduled) {
    return (
      <span className={clsx(styles.Chip, styles.RescheduledTba)}>
        {
          <Trans id="generic.raceStatus.rescheduledToBeAnnouncedShort">
            Décalée - Date incertaine
          </Trans>
        }
      </span>
    );
  }

  return null;

  // return (
  //   <span className={clsx(styles.Chip, styles.Tba)}>
  //     {<Trans id="generic.raceStatus.toBeAnnouncedShort">En attente de date</Trans>}
  //   </span>
  // );
};

export default EventStatusChip;
