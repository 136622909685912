import { IdentifyFn, PageFn, ResetFn, TrackFn } from "..";
import { TrackEventOptions } from "../types";

const ADS_MESUREMENT_ID = "AW-436649353";
const UA_MEASUREMENT_ID = "UA-188028099-2";
// const GA4_MEASUREMENT_ID = "G-VKS68CDY0N";

export const GA_DIMENSIONS = {
  ServiceWorkerStatus: "dimension1",
};

const identify: IdentifyFn = (user) => {
  // @ts-ignore
  // window.gtag("config", GA_MEASUREMENT_ID, {
  //   send_page_view: false,
  //   user_id: user.uid,
  // });
  // @ts-ignore
  window.gtag("set", {
    user_id: user.uid,
  });
};

const track: TrackFn = (event, ...args) => {
  if (event === "auth_signup") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["auth_signup"][0];
    // @ts-ignore
    return window.gtag("event", "sign_up", {
      method: properties.method,
    });
  }
  if (event === "share") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["share"][0];

    const { method, url, type } = properties;
    // https://developers.google.com/gtagjs/reference/event#share
    // @ts-ignore
    return window.gtag("event", "share", {
      method,
      content_type: type,
      item_id: url,
    });
  }

  if (event === "auth_login") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["auth_login"][0];

    // @ts-ignore
    return window.gtag("event", "login", {
      // @ts-ignore
      method: properties.method,
    });
  }

  if (event === "generic_addToFavorites") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["generic_addToFavorites"][0];
    // @ts-ignore
    return window.gtag("event", "add_to_wishlist", {
      items: [
        {
          item_id: `event:${properties.id}`, // event id
        },
      ],
    });
  }

  // @ts-ignore
  window.gtag("event", event, args?.[0]);
};

const page: PageFn = (properties) => {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages

  // Fix for consent update sending wrong page title
  // see axeptio.js
  // @ts-ignore
  window.globalPageTitle = properties.type || document.type;

  // @ts-ignore
  window.gtag("set", {
    page_title: properties.title || document.title,
    // @ts-ignore
    page_type: properties.type || document.type,
    page_path: properties.path || window.location.pathname,
  });

  // @ts-ignore
  window.gtag("config", UA_MEASUREMENT_ID, {
    page_title: properties.title || document.title,
    // @ts-ignore
    page_type: properties.type || document.type,
    page_path: properties.path || window.location.pathname,
    // @ts-ignore
    content_group1: properties.type || document.type || null,
  });

  // @ts-ignore
  window.gtag("config", ADS_MESUREMENT_ID, {
    page_title: properties.title || document.title,
    // @ts-ignore
    page_type: properties.type || document.type,
    page_path: properties.path || window.location.pathname,
    // @ts-ignore
    content_group1: properties.type || document.type || null,
  });

  // @ts-ignore
  // window.gtag("set", UA_MEASUREMENT_ID, {
  //   content_group1: properties.type || document.type || null,
  // });

  // @ts-ignore
  // window.gtag("event", "page_view", {
  //   page_title: properties.title || document.title,
  //   // @ts-ignore
  //   page_type: properties.type || document.type,
  //   page_path: properties.path || window.location.pathname,
  //   // @ts-ignore
  //   content_group1: properties.type || document.type || null,
  //   send_to: GA4_MEASUREMENT_ID,
  // });

  // @ts-ignore
  window.gtag("event", "page_view", {
    page_title: properties.title || document.title,
    // @ts-ignore
    page_type: properties.type || document.type,
    page_path: properties.path || window.location.pathname,
    // @ts-ignore
    content_group1: properties.type || document.type || null,
    send_to: ADS_MESUREMENT_ID,
  });
};

const reset: ResetFn = () => {
  // @ts-ignore
  window.gtag("set", {
    user_id: null,
  });
};

export default {
  identify,
  reset,
  track,
  page,
};
