const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const rgbStringToHex = (str: string): string =>
  // eslint-disable-next-line
  rgbToHex.apply(
    null,
    str.split(", ").map((i) => parseInt(i, 10)) as [r: number, g: number, b: number]
  );

export const toRgbString = (values: string): string => `rgb(${values})`;
