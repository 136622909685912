import clsx from "clsx";
import { groupBy, isEmpty } from "lodash";

import { createCloudinaryURL } from "@kavval/formatters";

import { Sponsor, SponsorGroup } from "./types";

const logoContainerSizes = {
  large: "min-h-[100px]",
  medium: "min-h-[60px]",
  small: "min-h-[40px]",
} as const;

const logoMaxWidth = {
  large: "max-w-[180px] md:max-w-[220px]",
  medium: "max-w-[100px] md:max-w-[160px]",
  small: "max-w-[60px] md:max-w-[80px]",
} as const;

const logoSizes = {
  large: ["h_100"],
  medium: ["h_60"],
  small: ["h_40"],
} as const;

const SponsorLogo = ({ sponsor, size }: { sponsor: Sponsor; size: SponsorGroup["size"] }) => {
  const logo = (
    <img src={createCloudinaryURL(sponsor.logo, ["e_trim", "c_fit", ...logoSizes[size]])} />
  );

  return (
    <div
      className={clsx(
        "grayscale  opacity-80 hover:grayscale-0 hover:opacity-100 transition-all",
        logoMaxWidth[size]
      )}
    >
      {sponsor.url ? (
        <a href={sponsor.url} target="_blank" rel="noopener noreferrer nofollow">
          {logo}
        </a>
      ) : (
        logo
      )}
    </div>
  );
};

const SponsorLine = ({
  sponsors,
  size,
}: {
  sponsors: SponsorGroup[];
  size: SponsorGroup["size"];
}) => {
  return (
    <div className="flex flex-row flex-wrap items-center justify-evenly gap-10 my-4">
      {sponsors.map((sponsor) => {
        return (
          <div key={sponsor.title}>
            {!isEmpty(sponsor.title) && (
              <div className="text-xs font-bold text-storm-grey mb-6 uppercase text-center md:text-left">
                {sponsor.title}
              </div>
            )}
            <div
              className={clsx(
                "flex flex-row flex-wrap items-center justify-center gap-10",
                logoContainerSizes[size]
              )}
            >
              {sponsor.items.map((sponsor) => (
                <SponsorLogo key={sponsor.logo} sponsor={sponsor} size={size} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SponsorsGrid = ({ sponsors }: { sponsors: SponsorGroup[] }) => {
  const groups = groupBy(sponsors, "size");

  return (
    <div className="space-y-10">
      {(["large", "medium", "small"] as SponsorGroup["size"][]).map((size) => {
        const group = groups[size];
        return group ? <SponsorLine key={size} sponsors={group} size={size} /> : null;
      })}
    </div>
  );
};

export default SponsorsGrid;
