import { ComponentProps, forwardRef } from "react";

import RadioCheckboxBase from "./RadioCheckboxBase";

const Radio = forwardRef<HTMLInputElement, Omit<ComponentProps<typeof RadioCheckboxBase>, "type">>(
  (props, ref) => {
    return <RadioCheckboxBase type="radio" ref={ref} {...props} />;
  }
);

Radio.displayName = "Radio";

export default Radio;
