import { Sha256 } from "@aws-crypto/sha256-js";
import { cloneDeep } from "lodash";
import md5 from "md5";

import { IdentifyFn, PageFn, ResetFn, TrackFn } from "..";
import { CartItemWithPrice, TrackEventOptions } from "../types";
import { CRITEO_PARTNER_ID } from "./constants";

// https://help.criteo.com/kb/guide/en/all-criteo-onetag-events-and-parameters-vZbzbEeY86/Steps/775825

let siteType = "d";

if (typeof window !== "undefined") {
  siteType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
      ? "m"
      : "d";
}

let commonEvents: any[] = [
  { event: "setAccount", account: CRITEO_PARTNER_ID },
  {
    event: "setSiteType",
    type: siteType,
  },
];

const getVisitorId = (): string | null => {
  try {
    const visitorId = localStorage.getItem("visitorId");

    if (visitorId) {
      return visitorId;
    }

    const newVisitorId = md5(new Date().getTime() + Math.random().toString());

    localStorage.setItem("visitorId", newVisitorId);

    return newVisitorId;
  } catch (e) {
    return null;
  }
};

const rawPush = (...items: any[]) => {
  // @ts-ignore
  window.criteo_q.push(...cloneDeep(items));
};

const pushQueue = (...items: any[]) => {
  const queueItems = [...commonEvents, ...items].filter(Boolean);
  rawPush(...queueItems);
};

const setup = () => {
  // @ts-ignore
  window.criteo_q ||= [];
  rawPush(...commonEvents);
};

const identify: IdentifyFn = (user) => {
  commonEvents.push({
    event: "setCustomerId",
    id: md5(user.id),
  });
  if (user.email) {
    const cleanedEmail = user.email.trim().toLowerCase();
    const hash = new Sha256();
    hash.update(cleanedEmail);
    hash.digest().then((hashedEmail) => {
      const hashArray = Array.from(new Uint8Array(hashedEmail));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

      commonEvents.push(
        {
          event: "setEmail",
          email: md5(cleanedEmail),
          hash_method: "md5",
        },
        {
          event: "setEmail",
          email: hashHex,
          hash_method: "sha256",
        }
      );
      pushQueue();
    });
  } else {
    pushQueue();
  }
};

const mapItems = (items: CartItemWithPrice) => {
  return { id: items.item_id, price: items.price, quantity: items.quantity || 1 };
};

const track: TrackFn = (event, ...args) => {
  if (event === "add_to_cart") {
    // TODO : améliorer l'inférence de type
    const { items } = args[0] as TrackEventOptions["add_to_cart"][0];

    return pushQueue({
      event: "addToCart",
      item: items.map(({ item_id, price, quantity }) => ({ id: item_id, price, quantity })),
    });
  }

  if (event === "view_item") {
    // TODO : améliorer l'inférence de type
    const { items } = args[0] as TrackEventOptions["view_item"][0];

    items.forEach(({ item_id, price }) => {
      return pushQueue({
        event: "viewItem",
        item: item_id,
        price,
      });
    });
  }

  if (event === "checkout_progress") {
    // TODO : améliorer l'inférence de type
    const properties = args[0] as TrackEventOptions["checkout_progress"][0];
    const { items, id } = properties;

    return pushQueue({
      event: "viewBasket",
      id,
      item: items.map(mapItems),
    });
  }

  if (event === "purchase") {
    // TODO : améliorer l'inférence de type
    const { id, items } = args[0] as TrackEventOptions["purchase"][0];

    return pushQueue({
      event: "trackTransaction",
      id: id,
      // new_customer: 1, // Indique s'il s'agit de la première commande du client
      item: items.map(mapItems),
    });
  }
};

const page: PageFn = () => {
  if (window.location.pathname === "/") {
    pushQueue({
      event: "viewHome",
    });
  } else {
    pushQueue({
      event: "viewPage",
    });
  }
};

const reset: ResetFn = () => {
  const visitorId = getVisitorId();

  if (visitorId !== null) {
    pushQueue({
      event: "setRetailerVisitorId",
      id: visitorId,
    });
  }
};

export default {
  identify,
  page,
  track,
  reset,
  setup,
};
