import { I18n } from "@lingui/core";
import { upperFirst } from "lodash";

import { formatDate } from "@kavval/formatters";
import { type Locale } from "@kavval/lingui-config/locales";

export const getApproxDate = ({
  date,
  i18n,
}: {
  /**
   * @description Date au format `YYYY-MM-DD` ou `YYYY-MM-(start|mid|end)`
   */
  date: string;
  i18n: I18n & { locale: Locale };
}) => {
  const [year, _, day] = date.split("-");

  const values = {
    month: formatDate(i18n.locale, `${date.substring(0, 7)}-01`, {
      month: "long",
    }),
    year,
  };

  let res: string;

  let position: "start" | "mid" | "end";

  if (/^(start|mid|end)$/.test(day)) {
    position = day as typeof position;
  } else {
    const dateAsNumber = parseInt(day, 10);

    if (dateAsNumber <= 10) {
      position = "start";
    } else if (dateAsNumber <= 20) {
      position = "mid";
    } else {
      position = "end";
    }
  }

  if (position === "start") {
    res = i18n.t("shared.startOfMonth", values);
  } else if (position === "mid") {
    res = i18n.t("shared.midOfMonth", values);
  } else if (position === "end") {
    res = i18n.t("shared.endOfMonth", values);
  } else {
    res = `${values.month} ${values.year}`;
  }

  return upperFirst(res);
};
