export enum UtmbCategory {
  "20K" = "20K",
  "50K" = "50K",
  "100K" = "100K",
  "100M" = "100M",
}

const values: string[] = Object.keys(UtmbCategory).map(
  (key) => UtmbCategory[key as keyof typeof UtmbCategory]
);

export default values;
