import { useCallback } from "react";

import { trpc } from "@booking/lib/trpc";

export type ProfileFields = {
  firstName: string;
  lastName: string;
  genre: "f" | "m";
  email: string;
  phone: string;
  birthdate: string;
  newsletter: boolean;
  customFields?: { [x: string]: boolean };
};

const useSaveProfile = () => {
  const { mutateAsync } = trpc.saveProfile.useMutation();

  const saveProfile = useCallback(
    async (profile: Partial<ProfileFields>) => {
      return await mutateAsync(profile);
    },
    [mutateAsync]
  );

  return saveProfile;
};

export default useSaveProfile;
