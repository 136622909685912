import { createElement, ForwardedRef, forwardRef, ReactNode } from "react";
import clsx from "clsx";

import Skeleton from "../Skeleton";
import styles from "./style.module.css";

const sizeProps = {
  width: "100%",
  height: "100%",
};

type Picture = {
  srcSet: string;
  media: string;
};
interface PictureWithContentProps {
  imageSrc: string;
  pictures?: Picture[];
  imageAlt?: string;
  // eslint-disable-next-line
  component?: any;
  innerClassName?: string;
  wrapperClassName?: string;
  pictureWrapperClassName?: string;
  children?: ReactNode;
  skeleton?: boolean;
  loading?: "lazy" | "eager";
}

const PictureWithContent = (
  {
    imageSrc,
    pictures,
    imageAlt,
    component = "div",
    loading = "lazy",
    innerClassName,
    wrapperClassName,
    children,
    skeleton,
    ...props
  }: PictureWithContentProps,
  // eslint-disable-next-line
  ref?: ForwardedRef<any>
) => {
  return createElement(
    component,
    {
      className: clsx(styles.PictureWithContent, wrapperClassName, skeleton && styles.WithSkeleton),
      ref,
      ...props,
    },
    <>
      <picture className={styles.Picture}>
        {pictures?.map((p) => <source key={p.srcSet} {...p} {...sizeProps} />)}
        <img loading={loading} alt={imageAlt} src={imageSrc} {...sizeProps} />
      </picture>
      <div className={clsx(styles.Content, innerClassName)}>{children}</div>
      {skeleton && <Skeleton containerClassName={clsx(styles.Skeleton)} />}
    </>
  );
};

export default forwardRef(PictureWithContent);
