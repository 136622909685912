import * as Sentry from "@sentry/react";
import React, { useEffect, useReducer } from "react";
import { t, Trans } from "@lingui/macro";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";

import {
  GenericNotFoundError,
  LoadingCentered,
  NotFoundMessage,
  PageWrapper,
  ShoppingCart,
  ShoppingCartLayout,
} from "@booking/components";
import OrderErrorMessage from "@booking/components/OrderErrorMessage";
import { isOfferErrorCode } from "@booking/components/RegistrationError";
import analytics from "@booking/lib/analytics";
import { trpc } from "@booking/lib/trpc";
import useAuthState from "@booking/pages/Booking/Auth/useAuthState";
import NotFoundPage from "@booking/pages/NotFound";
import {
  getCartAmount,
  getCartDetails,
  reducer,
  setTicket,
  setUser,
  stateInitializer,
  useStateLocalStorage,
} from "@booking/state";
import { ENV_VARS } from "@booking/utils/env-vars";

const Auth = React.lazy(() => import("@booking/pages/Booking/Auth"));
const Details = React.lazy(() => import("@booking/pages/Booking/Details"));
const Payment = React.lazy(() => import("@booking/pages/Booking/Payment"));

export enum BookingRoutePathEnum {
  Auth = "auth",
  Details = "details",
  Payment = "payment",
}

const Booking = () => {
  const { ticketShortId } = useParams();
  const [state, dispatch] = useReducer(
    reducer,
    { ticketShortId: ticketShortId! },
    stateInitializer
  );
  useStateLocalStorage(state);

  const {
    isLoading: isTicketLoading,
    error: ticketError,
    data: ticketFromQuery,
    // refetch: refetchTicket,
  } = trpc.getOfferByShortId.useQuery(ticketShortId!);
  const {
    isLoading: isProfileLoading,
    error: profileError,
    data: profile,
    refetch: refetchProfile,
  } = trpc.getProfile.useQuery();

  const isLoading = isTicketLoading || isProfileLoading;
  const error = ticketError || profileError;

  const { currentStep, ticket } = state;
  const activeRoutes: Array<BookingRoutePathEnum> =
    currentStep === BookingRoutePathEnum.Payment
      ? [BookingRoutePathEnum.Details, BookingRoutePathEnum.Payment]
      : [currentStep];
  const event = ticket?.raceEdition.race.event;
  const eventShortId = event?.shortId;

  const { setNewsletterOptin } = useAuthState({ onChange: refetchProfile });

  useEffect(() => {
    if (!isLoading) {
      if (!error) {
        if (ticketFromQuery) {
          dispatch(setTicket(ticketFromQuery)); // Needs to be set first (for now)
          dispatch(setUser(profile));
        }
      } else {
        Sentry.captureException(error);
        console.warn(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketFromQuery, profile, isLoading, error]);

  useEffect(() => {
    if (state.user) {
      analytics.identify(state.user, true);
    }
  }, [state.user]);

  if (isLoading || (ticketFromQuery && !ticket) /* le state n'est pas encore à jour */) {
    return (
      <PageWrapper>
        <LoadingCentered />
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <GenericNotFoundError />
      </PageWrapper>
    );
  }

  if (!ticket) {
    return <NotFoundPage pageType="book/checkout/not-found" />;
  }

  const { soldOnKavval, status } = ticket;

  if (!soldOnKavval) {
    return <Navigate to={eventShortId ? `/event/${eventShortId}` : "/"} />;
  }

  if (isOfferErrorCode(status)) {
    return (
      <PageWrapper
        logoHref={event?.href ? `https://www.${ENV_VARS.KAVVAL_DOMAIN}${event.href}` : undefined}
      >
        <NotFoundMessage
          title={t({
            id: "page.ticket.error.notAvailable.title",
            message: "Offre non disponible",
          })}
          tracking={{ pageType: "book/checkout/not-opened" }}
        >
          <OrderErrorMessage
            code={status}
            eventShortId={eventShortId!}
            // eslint-disable-next-line lingui/no-unlocalized-strings
            linkClassName="button button-primary mt-8"
          />
        </NotFoundMessage>
      </PageWrapper>
    );
  }

  if (status === "sold_out" || status === "expired") {
    return (
      <PageWrapper
        logoHref={event?.href ? `https://www.${ENV_VARS.KAVVAL_DOMAIN}${event.href}` : undefined}
      >
        <NotFoundMessage
          title={t({
            id: "page.ticket.error.notAvailable.title",
            message: "Offre non disponible",
          })}
          tracking={{
            pageType: status === "sold_out" ? "checkout/sold-out" : "checkout/expired",
          }}
        >
          <p>
            <Trans id="page.ticket.error.notAvailable.message">
              Désolé, cette offre n'est plus disponible
            </Trans>
          </p>
          <p>
            <Link
              className="button button-primary mt-8"
              to={eventShortId ? `/event/${eventShortId}` : "/"}
            >
              <Trans id="page.ticket.error.notAvailable.seeOffersButton">
                Voir toutes les offres
              </Trans>
            </Link>
          </p>
        </NotFoundMessage>
      </PageWrapper>
    );
  }

  const cart = getCartDetails(state);

  return (
    <PageWrapper
      logoHref={event?.href ? `https://www.${ENV_VARS.KAVVAL_DOMAIN}${event.href}` : undefined}
    >
      <ShoppingCartLayout
        rightContent={<ShoppingCart cart={cart} total={getCartAmount(cart || { items: [] })} />}
        withSellingPoints={!ticket!.sellerId || !/affiliation/.test(ticket!.sellerId)}
      >
        <Routes>
          <Route
            path={BookingRoutePathEnum.Auth}
            element={
              !activeRoutes.includes(BookingRoutePathEnum.Auth) ? (
                <Navigate to={`/ticket/${ticketShortId}/${currentStep}`} replace />
              ) : (
                <Auth state={state} setNewsletterOptin={setNewsletterOptin} />
              )
            }
          />
          <Route
            path={BookingRoutePathEnum.Details}
            element={
              !activeRoutes.includes(BookingRoutePathEnum.Details) ? (
                <Navigate to={`/ticket/${ticketShortId}/${currentStep}`} />
              ) : (
                <Details state={state} dispatch={dispatch} />
              )
            }
          />
          <Route
            path={BookingRoutePathEnum.Payment}
            element={
              !activeRoutes.includes(BookingRoutePathEnum.Payment) ? (
                <Navigate to={`/ticket/${ticketShortId}/${currentStep}`} />
              ) : (
                <Payment state={state} dispatch={dispatch} />
              )
            }
          />
          <Route
            path="/"
            element={<Navigate to={`/ticket/${ticketShortId}/${currentStep}`} replace />}
          />
        </Routes>
      </ShoppingCartLayout>
    </PageWrapper>
  );
};

export default Booking;
