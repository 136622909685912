import { ReactNode } from "react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { Fragment } from "react/jsx-runtime";

import { Maybe } from "@kavval/ts-utils";
import { Chip, TextReveal } from "@kavval/ui";

import { formatPrice } from "@booking/lib/formatters";
import useOfferAvailabilityMessages from "@booking/lib/hooks/useOfferAvailabilityMessages";
import useTextConditions from "@booking/pages/EventRaces/TicketContainer/useTextConditions";

import styles from "./styles.module.css";

type TicketContainerProps = {
  price?: {
    amount: number;
    currency: string;
    isMinPrice?: boolean;
    fees?: number;
  };
  description?: string | null;
  button?: ReactNode;
  limitedToEmployees?: boolean;
  bookable: boolean;
  title?: Maybe<string>;
  availableAt?: Maybe<string>;
  expiresAt?: Maybe<string>;
  maxRegistrations?: Maybe<number>;
  maxBirthdate?: Maybe<string>;
  minBirthdate?: Maybe<string>;
  remainingRegistrations?: Maybe<number>;
  noReveal?: boolean;
  showPriceAsterisk?: boolean;
};

const TicketContainer = ({
  price,
  bookable,
  title,
  limitedToEmployees,
  description,
  button,
  availableAt,
  expiresAt,
  maxRegistrations,
  maxBirthdate,
  minBirthdate,
  remainingRegistrations,
  noReveal,
  showPriceAsterisk,
}: TicketContainerProps) => {
  const { i18n } = useLingui();

  const priceText = !price ? null : price.amount === 0 ? (
    <span className="text-lg tracking-normal">
      <Trans id="generic.free">Gratuit</Trans>
    </span>
  ) : (
    new Intl.NumberFormat(i18n.locale, {
      style: "currency",
      currencyDisplay: "narrowSymbol",
      currency: price.currency,
      minimumFractionDigits: 0,
    })
      .formatToParts(price.amount / 100)
      .map(({ type, value }, index) =>
        type === "currency" ? (
          <span className="text-lg align-text-top" key={value}>
            {value}
          </span>
        ) : (
          <Fragment key={`${type}${value}${index}`}>{value}</Fragment>
        )
      )
  );

  const feesText = price?.fees ? formatPrice(price.fees, price.currency) : null;

  const conditions = useTextConditions({
    availableAt,
    expiresAt,
    maxRegistrations,
    maxBirthdate,
    minBirthdate,
  });
  const offerAvailabilityMessages = useOfferAvailabilityMessages({
    expiresAt,
    remainingRegistrations,
    bookable,
  });

  if (limitedToEmployees) {
    offerAvailabilityMessages.push(
      <Trans id="page.tickets.limitedToEmployees">Offre Club Finishers</Trans>
    );
  }

  return (
    <div className={clsx(!bookable && styles.NotBookable, styles.Ticket)}>
      <div className="grow p-4 flex flex-col justify-center">
        {!isEmpty(title) && (
          <div
            className={clsx(
              "text-lg md:text-xl text-dark-blue lg:flex flex-row flex-wrap items-center gap-2",
              !bookable && "!text-opacity-60"
            )}
          >
            <span
              className={clsx(
                limitedToEmployees &&
                  "underline decoration-orange decoration-2 underline-offset-4 bg-orange bg-opacity-10",
                "font-semibold"
              )}
            >
              {title}
            </span>
            {offerAvailabilityMessages.map((content, index) => (
              <Chip color="secondary" size="small" key={index}>
                {content}
              </Chip>
            ))}
          </div>
        )}
        {isEmpty(title) && limitedToEmployees && (
          <div className="mb-4">
            <Chip color="secondary" size="small">
              <Trans id="page.tickets.limitedToEmployees">Offre Club Finishers</Trans>
            </Chip>
          </div>
        )}
        <div className={clsx("text-fjord-grey", !bookable && "!text-opacity-60")}>
          {conditions}
          <p>
            {noReveal ? (
              description
            ) : (
              <TextReveal
                showMore={
                  <Trans id="page.tickets.show-description">Voir la description du tarif</Trans>
                }
                text={description || ""}
              />
            )}
          </p>
        </div>
      </div>
      <div className={styles.TicketPriceBlock}>
        {price?.isMinPrice && (
          <span className="text-sm text-dark-blue/70 leading-tight grow">
            <Trans id="page.tickets.priceFrom">à partir de</Trans>
          </span>
        )}
        <span className="text-2xl md:text-3xl font-bold tracking-tighter pr-2 md:pr-0">
          {priceText}
          {showPriceAsterisk && <span className="text-xl font-bold align-super"> *</span>}
        </span>
        {feesText ? (
          <span className="text-sm text-dark-blue/70 leading-tight grow">
            <Trans id="page.tickets.fees">Frais de dossier {feesText}</Trans>
          </span>
        ) : null}
        {button}
      </div>
    </div>
  );
};

export default TicketContainer;
