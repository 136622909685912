import { ComponentProps, FC } from "react";

import SvgIcon from "../SvgIcon";

const Icon: FC<ComponentProps<typeof SvgIcon>> = (props) => (
  <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
    <path d="M24 48a24 24 0 100-48 24 24 0 000 48z" fill="#9DA0B6" />
    <path
      d="M33.3 14.7H14.7c-1.3 0-2.4 1-2.4 2.3v14c0 1.3 1 2.3 2.4 2.3h18.6c1.3 0 2.4-1 2.4-2.3V17c0-1.3-1-2.3-2.4-2.3zm-.4 5l-8.3 5c-.4.3-.8.3-1.2 0l-8.3-5a1 1 0 111-1.7l7.9 4.8 7.8-4.9a1 1 0 111 1.7z"
      fill="#fff"
    />
  </SvgIcon>
);

export default Icon;
