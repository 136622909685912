import { ComponentProps, FC, MouseEvent, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

import Label from "../Label";

type FormGroupProps = {
  error?: ReactNode | boolean;
  label?: ComponentProps<typeof Label>["label"];
  rightLabel?: ComponentProps<typeof Label>["rightLabel"];
  id?: string;
  helperText?: ReactNode;
  className?: string;
  onLabelClick?: (e: MouseEvent) => void;
};

const FormGroup: FC<PropsWithChildren<FormGroupProps>> = ({
  error,
  label,
  rightLabel,
  helperText,
  id,
  children,
  className,
  onLabelClick,
}) => {
  return (
    <div className={clsx("form-group", !!error && "form-error", className)}>
      {label && (
        <Label
          label={label}
          htmlFor={id}
          rightLabel={rightLabel}
          {...(onLabelClick && { onClick: onLabelClick })}
        />
      )}
      {children}
      {helperText && <div className="form-help-text">{helperText}</div>}
      {error && typeof error !== "boolean" ? <div className="form-help-text">{error}</div> : null}
    </div>
  );
};

export default FormGroup;
