import { stringify } from "querystring";
import Cookies from "js-cookie";
import { castArray, minBy, pick, sumBy } from "lodash";

import {
  AnalyticsUser,
  CartItem,
  CartItemWithPrice,
  EventName,
  GetCartItemOptions,
  GetCartItemOptionsWithOffer,
  TrackEventOptions,
} from "./types";

export * from "./types";

export { default as criteo } from "./criteo";
export { CRITEO_PARTNER_ID, CRITEO_TAG_URL } from "./criteo/constants";
export { default as facebook } from "./facebook";
export { default as google, GA_DIMENSIONS } from "./google";
export { default as plausible } from "./plausible";
export { default as wisepops } from "./wisepops";

const DISABLE_ANALYTICS_KEY = "disableKavvalAnalytics";

type AnalyticsService = {
  identify?: IdentifyFn;
  track?: TrackFn;
  page?: PageFn;
  reset?: ResetFn;
  setup?: (options: any) => void;
};

const eventProperties = [
  "id",
  "shortId",
  "name",
  "slug",
  "bannerImage",
  "soldOnKavval",
  "tags",
  "href",
  "isSubscriptionActive",
  "subscriptionType",
  "cityName",
  "level1AdminAreaName",
  "countryName",
] as const;

const cleanEventProperties = (event: any) => {
  const result = pick(event, eventProperties);

  if (event.breadcrumb) {
    // @ts-ignore
    result.breadcrumb = event.breadcrumb.map(({ label }) => label).join(" > ");
  }

  return result;
};

const cleanPageViewProperties = (properties: any) => {
  const result = { ...properties };

  if (properties?.data?.event) {
    result.data = {
      ...properties.data,
      event: cleanEventProperties(properties.data.event),
    };
  }

  return result;
};

function getCartItem<T extends GetCartItemOptions>(
  options: T
): T extends GetCartItemOptionsWithOffer ? CartItemWithPrice : CartItem;
function getCartItem(options: GetCartItemOptions): CartItemWithPrice | CartItem {
  const { event, race, offer } = options;

  if (offer) {
    return {
      item_id: `${race ? "race" : "event"}:${race?.id || event.id}`,
      item_name: race?.formattedTitle || race?.name || "unknown",
      item_brand: event.slug,
      item_category: race?.discipline,
      item_variant: offer?.title,
      price: (offer.price + offer.fees) / 100,
      currency: offer?.currency || "EUR",
      google_business_vertical: "retail",
    };
  }

  const price = race?.minPrice || event?.minPrice;
  const currency = race?.currency || "EUR";

  return {
    item_id: `${race ? "race" : "event"}:${race?.id || event.id}`,
    item_name: race?.formattedTitle || race?.name || "unknown",
    item_brand: event.slug,
    item_category: race?.discipline,
    price: price ? price / 100 : undefined,
    currency,
    google_business_vertical: "retail",
  };
}

class Analytics {
  services: AnalyticsService[] = [];
  production = false;
  enabled: boolean | undefined = undefined;
  previousPath: string | null = null;
  options: any;

  constructor(
    services: AnalyticsService[],
    options: { production: boolean } & Record<string, any>
  ) {
    this.services = services;
    this.production = options.production;
    this.options = options;
    this.setup();
    return this;
  }

  setup() {
    if (typeof window === "undefined") return;
    this.services.forEach((service) => service.setup?.(this.options));
  }

  disable() {
    this.enabled = false;
    try {
      localStorage.setItem(DISABLE_ANALYTICS_KEY, "1");
      // eslint-disable-next-line
    } catch (e) {}
  }

  enable() {
    this.enabled = true;
    try {
      localStorage.removeItem(DISABLE_ANALYTICS_KEY);
      // eslint-disable-next-line
    } catch (e) {}
  }

  isEnabled() {
    if (typeof this.enabled !== "undefined") return this.enabled;

    try {
      this.enabled = localStorage.getItem(DISABLE_ANALYTICS_KEY) !== "1";
      // eslint-disable-next-line
    } catch (e) {
      this.enabled = true;
    }

    this.log("analyticsEnabled", this.enabled);

    return this.enabled;
  }

  log(type: string, params: any = null) {
    if (!this.production) {
      // eslint-disable-next-line
      console.log(
        `%canalytics (${type}) : %c${JSON.stringify(params)}`,
        "color: #bada55; font-weight: bold",
        "color: #888"
      );
    }
  }

  // @ts-ignore
  callServices(method: string, ...args) {
    if (!this.isEnabled()) return;

    this.services.forEach((service) => {
      // eslint-disable-next-line
      if (service.hasOwnProperty(method)) {
        try {
          // @ts-ignore
          service[method](...args);
        } catch (e) {
          if (!this.production) {
            console.error(e);
          }
          // Just in case
        }
      }
    });
  }

  identify(user: AnalyticsUser, saveCookie = false) {
    this.log("identify", { user });

    if (saveCookie) {
      // Voir apps/frontend/pages/api/events-log/index.ts
      if (user?.id) {
        Cookies.set("_kvvluid", user.id, {
          expires: 365 * 2,
          domain: ".finishers.com",
        });
      } else {
        Cookies.remove("_kvvluid");
      }
    }

    this.callServices("identify", user);
  }

  track<T extends EventName>(event: T, ...args: TrackEventOptions[T]) {
    this.log("track", { event, properties: args[0] });
    this.callServices("track", event, args[0]);
  }

  page(properties: { path?: string; data?: any; type: any; title?: string }) {
    const path = properties.path || window.location.pathname;
    if (path === this.previousPath) {
      return;
    }

    this.previousPath = path;

    const cleanedProperties = cleanPageViewProperties(properties);
    this.log("page", { properties: cleanedProperties });
    this.callServices("page", cleanedProperties);
  }

  viewItem(product: GetCartItemOptions | GetCartItemOptions[]) {
    const items = castArray(product).map(getCartItem);

    const data: any = {
      items,
    };

    const cheapestProduct = minBy(items, "price");
    const minPrice = cheapestProduct?.price;

    if (minPrice) {
      data.value = minPrice;
      data.currency = cheapestProduct.currency;
    }
    this.track("view_item", data);
  }

  reset() {
    this.log("reset");
    this.callServices("reset");
  }

  trackBookCTAClick(/*product: GetCartItemOptions*/) {
    // this.track("view_offers", {
    //   items: [getCartItem({ event, race })],
    // });
  }

  trackAddToCart(product: GetCartItemOptionsWithOffer) {
    const item = getCartItem(product);

    this.track("add_to_cart", {
      value: item.price,
      currency: item.currency,
      items: [item],
    });

    this.track("begin_checkout", {
      value: item.price,
      currency: item.currency,
      items: [item],
    });
  }

  trackCheckoutProgress(
    orderId: string,
    products: GetCartItemOptionsWithOffer[],
    step: "auth" | "form" | "payment"
  ) {
    let checkout_step;

    const items = products.map(getCartItem);

    if (step === "auth") {
      checkout_step = 1;
    } else if (step === "form") {
      checkout_step = 2;
    } else if (step === "payment") {
      checkout_step = 3;
    }

    this.track("checkout_progress", {
      id: orderId,
      checkout_step,
      value: sumBy(items, "price"),
      currency: items[0].currency,
      items: items,
    });
  }

  trackCheckoutSuccess(orderId: string, products: GetCartItemOptionsWithOffer[]) {
    const items = products.map(getCartItem);

    this.track("purchase", {
      id: orderId,
      transaction_id: orderId,
      value: sumBy(items, "price"),
      currency: items[0].currency,
      items: items,
    });
  }
}

type ExternalLinkType = TrackEventOptions["view_redirection_page"][0]["type"];

export type ExternalPageParams = {
  url: string;
  event?: string;
  pr?: "events";
  pi: string;
  ad?: "0" | "1";
  type?: ExternalLinkType;
};

const isFinishersLink = (href: string) =>
  /^\//.test(href) ||
  /^https:\/\/[^/]+.finishers.com.*/.test(href) ||
  /^https:\/\/([^/]+.)?atleta.cc.*/.test(href);

export function buildExternalLinkUrl({
  href,
  pageSlug,
  pageId,
  showAds,
  type,
}: {
  href: string;
  pageSlug: string;
  pageId: string;
  showAds?: boolean;
  type?: ExternalLinkType;
}) {
  const params: ExternalPageParams = {
    url: href,
    event: pageSlug,
    pi: pageId,
    ad: showAds ? "1" : "0",
    type: type || "unknown",
  };

  return isFinishersLink(href) ? href : `/external?${stringify(params)}`;
}

// Convention à (essayer de) suivre pour nommer les events :
// https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
// Notre convention : "categorie_action" (minuscules, sans espace, séparé par 1 seul underscore)
// Exemples :
// - authentication_failed
// - authentication_succeeded
// - payment_created
// - search_changed
// - contactForm_opened

export type IdentifyFn = InstanceType<typeof Analytics>["identify"];
export type TrackFn = InstanceType<typeof Analytics>["track"];
export type PageFn = InstanceType<typeof Analytics>["page"];
export type ResetFn = InstanceType<typeof Analytics>["reset"];

export default Analytics;
