import { red } from "@mui/material/colors";
import { createTheme, PaletteColor, PaletteColorOptions } from "@mui/material/styles";
import { alpha, darken } from "@mui/system";

import {
  breakpointLg,
  breakpointMd,
  breakpointSm,
  breakpointXl,
  breakpointXs,
  colorDarkBlue,
  colorElectricBlue,
  colorFjordGrey,
  colorOrange,
  colorSnowGrey,
  colorStormGrey,
  shadow2xl,
} from "@kavval/design-tokens";
import { toRgbString } from "@kavval/formatters";

declare module "@mui/material/styles" {
  interface TypeBackground {
    highlighted: string;
  }
  interface TypeText {
    colored: string;
  }
  interface Palette {
    stormGrey: PaletteColor;
    fjordGrey: PaletteColor;
    snowGrey: PaletteColor;
    darkBlue: PaletteColor;
    electricBlue: PaletteColor;
  }
  interface PaletteOptions {
    stormGrey: PaletteColorOptions;
    fjordGrey: PaletteColorOptions;
    snowGrey: PaletteColorOptions;
    darkBlue: PaletteColorOptions;
    electricBlue: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    snowGrey: true;
  }
  interface ButtonPropsSizeOverrides {
    xs: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    snowGrey: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    snowGrey: true;
    darkBlue: true;
  }
}

const spacing = 8;

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: breakpointXs,
      sm: breakpointSm,
      md: breakpointMd,
      lg: breakpointLg,
      xl: breakpointXl,
    },
  },
  spacing: spacing,
  typography: {
    fontFamily: "var(--font-family-body)",
    fontSize: 16,
    button: { fontWeight: 700 },
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      main: toRgbString(colorElectricBlue),
    },
    secondary: {
      main: toRgbString(colorDarkBlue),
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
      highlighted: toRgbString(colorSnowGrey),
    },
    text: {
      primary: toRgbString(colorDarkBlue),
      secondary: toRgbString(colorStormGrey),
      colored: toRgbString(colorElectricBlue),
    },
    stormGrey: {
      main: toRgbString(colorStormGrey),
    },
    fjordGrey: {
      main: toRgbString(colorFjordGrey),
      // faded2: alpha(toRgbString(colorFjordGrey), 0.2),
    },
    snowGrey: {
      main: toRgbString(colorSnowGrey),
      // faded2: alpha(toRgbString(colorSnowGrey), 0.2),
    },
    darkBlue: {
      main: toRgbString(colorDarkBlue),
      // faded2: alpha(toRgbString(colorDarkBlue), 0.2),
    },
    electricBlue: {
      main: toRgbString(colorElectricBlue),
      // faded2: alpha(toRgbString(colorElectricBlue), 0.2),
    },
  },
  shape: {
    // smallBorderRadius: 2,
    borderRadius: 3,
    // largeBorderRadius: 8,
    // extraLargeBorderRadius: 20,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: spacing * 2, // 16px
          paddingRight: spacing * 2, // 16px
        },
        maxWidthXl: {
          // 600px
          [`@media (min-width:${breakpointSm}px)`]: {
            // same as [theme.breakpoints.up("sm")]: {
            paddingLeft: spacing * 4, // 32px
            paddingRight: spacing * 4, // 32px
          },
          // 900px
          [`@media (min-width:${breakpointMd}px)`]: {
            // same as [theme.breakpoints.up("md")]: {
            paddingLeft: spacing * 7, // 56px
            paddingRight: spacing * 7, // 56px
          },
          // 1024
          [`@media (min-width:${breakpointLg}px)`]: {
            // same as [theme.breakpoints.up("lg")]: {
            paddingLeft: spacing * 12, // 96px
            paddingRight: spacing * 12, // 96px
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: "uppercase",
        },
        outlined: {
          borderColor: "currentColor",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        text: {},
      },
      variants: [
        {
          props: { size: "xs" },
          style: {
            fontSize: 14,
            padding: "0 12px",
            minWidth: 0,
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: "5px 16px",
          },
        },
        {
          props: { color: "snowGrey" },
          style: {
            color: toRgbString(colorDarkBlue),
            "&:hover": { backgroundColor: alpha(toRgbString(colorDarkBlue), 0.05) },
          },
        },
        {
          props: { variant: "contained", color: "snowGrey" },
          style: {
            backgroundColor: toRgbString(colorSnowGrey),
            "&:hover": {
              backgroundColor: darken(toRgbString(colorSnowGrey), 0.06),
            },
          },
        },
        {
          props: { variant: "outlined", color: "snowGrey" },
          style: {
            color: toRgbString(colorDarkBlue),
            borderColor: alpha(toRgbString(colorDarkBlue), 0.1),
            "&:hover": {
              borderColor: alpha(toRgbString(colorDarkBlue), 0.1),
              backgroundColor: alpha(toRgbString(colorDarkBlue), 0.05),
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            "&:hover": {
              backgroundColor: alpha(toRgbString(colorElectricBlue), 0.1),
            },
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: toRgbString(colorElectricBlue),
            transition: "filter 250ms",
            "&:hover": {
              backgroundColor: toRgbString(colorElectricBlue),
              filter: "brightness(.9)",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            background: toRgbString(colorDarkBlue),
            "&:hover": {
              backgroundColor: alpha(toRgbString(colorDarkBlue), 0.9),
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: toRgbString(colorStormGrey),
          borderRadius: 3,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
        outlined: {
          transform: "translate(18px, 14px) scale(1);",
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&:hover.Mui-selected": {
            backgroundColor: toRgbString(colorStormGrey),
          },
          "&.Mui-selected, &:hover.Mui-selected": {
            backgroundColor: toRgbString(colorElectricBlue),
            // color: "white",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: toRgbString(colorDarkBlue),
          fontWeight: 600,
          fontSize: 16,

          "&.Mui-focused": {
            color: toRgbString(colorDarkBlue),
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: { marginTop: 12 },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 16,
          marginBottom: 16,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 16,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "inherit",
          "&.Mui-error input": {
            borderColor: red.A400,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "10px 44px 10px 20px !important",
          fontSize: 16,
          border: "1px solid rgba(var(--color-storm-grey), .3) ",
          borderRadius: 3,
          fontWeight: 700,

          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        outlined: {
          borderColor: "rgba(var(--color-storm-grey), .3)",
        },
        // selectMenu: { minHeight: "16px" },
        icon: {
          color: toRgbString(colorOrange),
          right: 15,
          fontSize: 20,
          top: "calc(50% - 9px)",
        },
        iconOutlined: {
          right: 15,
        },
      },
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          padding: "10px 44px 10px 20px !important",
          fontSize: 16,
          border: "1px solid rgba(var(--color-storm-grey), .3) !important",
          borderRadius: 3,
          fontWeight: 700,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: { borderRadius: 2 },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 24,
          padding: 0,
          margin: spacing,
        },
        edgeStart: {
          marginLeft: 0,
        },
        colorPrimary: {
          "&.MuiSwitch-checked": {
            color: "#FFF",
          },
        },
        switchBase: {
          padding: 3,
          "&.Mui-checked": {
            transform: "translateX(17px)",
            color: "#FFF",
            "& + .MuiSwitch-track": {
              backgroundColor: toRgbString(colorElectricBlue),
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 18,
          height: 18,
          backgroundColor: "white",
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: toRgbString(colorStormGrey),
          opacity: "1 !important",
          transition: ".2s background-color border",
        },
      },
    },
  },
});

theme.shadows[24] = shadow2xl.join(", ");

// console.log(theme);

export default theme;
