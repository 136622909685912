import { t } from "@lingui/macro";

export default function getMessages() {
  const replacePrivacyLink = (message: string) =>
    message.replace(
      "<a>",
      `<a class="cc-link" href="https://www.finishers.com/docs/privacy-policy" target="_blank">`
    );

  return {
    consent_modal: {
      title:
        "🍪 " + t({ id: "cookies.consent-modal.title", message: "2 ou 3 cookies au ravito ?" }),
      description: replacePrivacyLink(
        t({
          id: "cookies.consent-modal.description",
          message: `Hello ! Nous utilisons quelques cookies pour savoir d'où viennent nos Finishers et comment ils utilisent le site, c'est ok pour toi ? <a>En savoir plus</a>`,
        })
      ),
      primary_btn: {
        text: t({ id: "cookies.consent-modal.primary-button", message: "OK pour moi" }),
        role: "accept_all", // 'accept_selected' or 'accept_all'
      },
      secondary_btn: {
        text: t({ id: "cookies.consent-modal.reject-button", message: "J'ai des allergies" }),
        role: "settings", // 'settings' or 'accept_necessary'
      },
    },
    settings_modal: {
      title: t({ id: "cookies.settings-modal.title", message: "Préférences Cookies" }),
      save_settings_btn: t({ id: "cookies.settings-modal.save-button", message: "Enregistrer" }),
      accept_all_btn: t({
        id: "cookies.settings-modal.accept-all-button",
        message: "Tout accepter",
      }),
      reject_all_btn: t({
        id: "cookies.settings-modal.reject-all-button",
        message: "Tout refuser",
      }), // optional, [v.2.5.0 +]
      cookie_table_headers: [
        { col1: "Name" },
        { col2: "Domain" },
        { col3: "Expiration" },
        { col4: "Description" },
        { col5: "Type" },
      ],
      blocks: [
        {
          title: t({
            id: "cookies.settings-modal.section.intro.title",
            message: "Utilisation des cookies",
          }),
          description: t({
            id: "cookies.settings-modal.section.intro.description",
            message:
              "Nous utilisons des cookies pour mesurer notre audience, entretenir la relation avec toi et t'adresser de temps à autre du contenu qualitif ainsi que de la publicité. Tu peux sélectionner ici ceux que tu nous autorises à garder.",
          }),
        },
        {
          title: t({ id: "cookies.settings-modal.section.base.title", message: "Cookies de base" }),
          description: t({
            id: "cookies.settings-modal.section.base.description",
            message: `Ils visent principalement à améliorer la navigation et la sécurité sur notre site.`,
          }),
          toggle: {
            value: "necessary",
            enabled: true,
            readonly: true,
          },
        },
        {
          title: t({
            id: "cookies.settings-modal.section.analytics.title",
            message: "Cookies de statistiques",
          }),
          description: t({
            id: "cookies.settings-modal.section.analytics.description",
            message:
              "Ils nous permettent savoir comment nos Finishers sont arrivés sur notre site et d'obtenir des statistiques d'utilisation du site. Pratique pour savoir ce que nous devons améliorer !",
          }),
          toggle: {
            value: "analytics",
            enabled: false,
            readonly: false,
          },
          // cookie_table: [
          //   {
          //     col1: "^_ga",
          //     col2: "google.com",
          //     col3: "2 years",
          //     col4: "description ...",
          //     col5: "Permanent cookie",
          //     is_regex: true,
          //   },
          //   {
          //     col1: "_gid",
          //     col2: "google.com",
          //     col3: "1 day",
          //     col4: "description ...",
          //     col5: "Permanent cookie",
          //   },
          // ],
        },

        {
          title: t({
            id: "cookies.settings-modal.section.ads.title",
            message: "Cookies publicitaires",
          }),
          description: t({
            id: "cookies.settings-modal.section.ads.description",
            message:
              "En cochant cette case, vous augmenterez vos chances de voir une publicité Finishers sur internet... plutôt qu'une pub sur la prochaine compétition internationale d'Aquaponey qui ne vous intéresse pas.",
          }),
          toggle: {
            value: "ads",
            enabled: false,
            readonly: false,
          },
          // cookie_table: [
          //   {
          //     col1: "^_ga",
          //     col2: "google.com",
          //     col3: "2 years",
          //     col4: "description ...",
          //     col5: "Permanent cookie",
          //     is_regex: true,
          //   },
          //   {
          //     col1: "_gid",
          //     col2: "google.com",
          //     col3: "1 day",
          //     col4: "description ...",
          //     col5: "Permanent cookie",
          //   },
          // ],
        },
        {
          title: t({ id: "cookies.settings-modal.section.more.title", message: "Plus d'infos" }),
          description: replacePrivacyLink(
            t({
              id: "cookies.settings-modal.section.more.description",
              message: `N'hésite pas à consulter notre <a>politique de confidentialité</a> pour en savoir plus.`,
            })
          ),
        },
      ],
    },
  } as const;
}
