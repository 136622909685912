import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { IdentifyFn, TrackFn } from "@kavval/analytics";

import { ENV_VARS } from "@booking/utils/env-vars";

if (ENV_VARS.IS_PROD) {
  LogRocket.init("tlvqtx/billetterie", {
    network: {
      requestSanitizer: (request) => {
        // scrub header pair from request
        // @ts-ignore
        request.headers["authorization"] = null;

        // make sure you return the modified request
        return request;
      },
    },
  });
  setupLogRocketReact(LogRocket);
}

const identify: IdentifyFn = function identify(user) {
  if (!ENV_VARS.IS_PROD) return;

  LogRocket.identify(user.id, {
    name: user.pseudo as string,
    email: user.email as string,
    // Add your own custom user variables here, ie:
    // subscriptionType: 'pro'
  });

  Sentry.getCurrentScope().setExtra("sessionURL", LogRocket.sessionURL);
};

const track: TrackFn = function track(event, ...args) {
  LogRocket.track(event);
};

export default {
  identify,
  track,
};
