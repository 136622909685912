import { v4 as uuidv4 } from "uuid";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/src/cookieconsent.js";

import getMessages from "./messages";

let cookieConsent: any;

export const init = () => {
  if (cookieConsent) {
    return cookieConsent;
  }

  // @ts-ignore
  cookieConsent = window.initCookieConsent();

  cookieConsent.run({
    // delay: 5000,
    cookie_expiration: 365,
    use_rfc_cookie: true,
    hide_from_bots: true,
    current_lang: "fr",
    cookie_domain: "finishers.com",
    page_scripts: true,
    gui_options: {
      consent_modal: {
        layout: "cloud",
        position: "bottom left",
        swap_buttons: true,
      },
      settings_modal: {
        layout: "box",
      },
    },

    onFirstAction: function () {
      cookieConsent.set("data", {
        value: {
          uid: uuidv4(),
          date: new Date().toISOString(),
        },
      });
    },

    onChange: function () {
      cookieConsent.set("data", {
        value: {
          ...cookieConsent.get("data"),
          date: new Date().toISOString(),
        },
      });
    },

    languages: {
      fr: getMessages(),
    },
  });

  return cookieConsent;
};

export const show = () => {
  if (!cookieConsent) {
    init();
  }

  cookieConsent.showSettings(0);
};
