// import styles from "./styles.module.css";

import { ReactNode } from "react";
import { Trans } from "@lingui/macro";
import clsx from "clsx";

import { ActivityEnum, DisciplineDefaultPicture } from "@kavval/constants";
import { createCloudinaryURL } from "@kavval/formatters";
import MapPinOutlinedIcon from "@kavval/ui/OutlinedIcons/MapPinOutlinedIcon";

const getEventPicture = (event: { shortId: string; bannerImage?: string | null }) => {
  let image = event.bannerImage;

  // HACK CRADOS
  if (event.shortId === "G7rHK" /* LUT BN */) {
    image = "v1682521223/app/njuko/lut-2023/ecom-header_snuj3c";
  }

  return createCloudinaryURL(image || DisciplineDefaultPicture[ActivityEnum.Trail], [
    "h_160",
    "w_400",
    "ar_1:1",
    "g_auto",
    "c_lfill",
    "q_auto:low",
  ]);
};

type ShoppingCartContainerProps = {
  event: {
    name: string;
    city?: {
      name?: string | null;
    };
  } & Parameters<typeof getEventPicture>[0];
  children?: ReactNode;
  showOfferWarning?: boolean;
};

const ShoppingCartContainer = ({
  event,
  children,
  showOfferWarning,
}: ShoppingCartContainerProps) => {
  return (
    <div className={clsx("mb-6 md:mb-0 shadow-xs md:shadow-xl")}>
      <div
        className={clsx("min-h-[110px] md:min-h-[160px] bg-no-repeat bg-cover bg-center")}
        style={{
          backgroundImage: `
          linear-gradient(360deg, rgb(10 18 43 / 5%) 0%, rgb(10 18 43 / 0%) 20px),
          url(${getEventPicture(event)})`,
        }}
      ></div>
      <div className="px-4 py-4 bg-white">
        <div className="typo-h3 leading-none">{event.name}</div>
        {event.city?.name && (
          <div className="text-sm text-storm-grey flex flex-row items-center mt-1">
            <MapPinOutlinedIcon className="mr-1 text-base" />
            {event.city.name}
          </div>
        )}
        {children && children}
      </div>
      {showOfferWarning && (
        <div className="bg-orange px-4 py-3 md:py-4 text-white font-bold">
          <Trans id="component.ShoppingCartContainer.limitedOfferWarning">
            Offre limitée, réserve vite !
          </Trans>
        </div>
      )}
    </div>
  );
};

export default ShoppingCartContainer;
