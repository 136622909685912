export enum DataStatusEnum {
  PendingBrief = "pending_brief",
  BriefDone = "brief_done",
  Wip = "wip",
  PendingReview = "pending_review",
  ChangesNeeded = "changes_needed",
  PendingReview2 = "pending_review_2",
  Published = "published",
  PublishedNoText = "published_notext",
  PublishedYLW = "published_ylw",
  AutoTranslated = "auto_translated",
}

const values = Object.values(DataStatusEnum);

export default values;
