"use client";

import { useCallback } from "react";
import { useLingui } from "@lingui/react";
import Cookies from "js-cookie";
import { sortBy } from "lodash";

import { localeNames as localeConstants } from "@kavval/constants";
import locales, { type Locale } from "@kavval/lingui-config/locales";

let ENV = "production";

export const sortedLocales = sortBy(locales, (l) => {
  if (l === "cs") {
    return "CZ";
  }
  if (l === "el") {
    return "EL";
  }
  return localeConstants[l].replace(/^[^ ]+ /, "");
});

try {
  // @ts-ignore
  ENV = process.env.KAVVAL_ENV || process.env.NODE_ENV || "production";
} catch (e) {
  try {
    // @ts-ignore
    ENV = import.meta.env.VITE_KAVVAL_ENV || import.meta.env.VITE_NODE_ENV || "production";
  } catch (e) {
    //
  }
}

const localeNames = {
  ...localeConstants,
  "zu-ZA": "🏴‍☠️ Pseudo",
};

type LocaleSwitcherProps = {
  onChange: (locale: Locale) => void;
  showPseudoLocale?: true;
};

const localesWithPseudo = [...sortedLocales, "zu-ZA"] as const;

const LocaleSwitcher = ({ onChange, showPseudoLocale }: LocaleSwitcherProps) => {
  const { i18n } = useLingui();
  const { locale } = i18n;

  const onSelect = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const newLocale = e.target.value as Locale;
      Cookies.set("NEXT_LOCALE", newLocale, {
        expires: 365 * 2,
        domain: ".finishers.com",
      });
      onChange(newLocale);
    },
    [onChange]
  );

  return (
    <select className="form-select" value={locale} onChange={onSelect}>
      {(showPseudoLocale || ENV !== "production" ? localesWithPseudo : sortedLocales).map(
        (locale) => (
          <option key={locale} value={locale}>
            {localeNames[locale]}
          </option>
        )
      )}
    </select>
  );
};

export default LocaleSwitcher;
