import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import { OfferStatusEnum } from "@api/db/models/Offer/types";
import { OfferErrorCodeEnum } from "@api/db/models/Order/errors";

const OrderErrorMessage = ({
  code,
  eventShortId,
  linkClassName,
  paragraphClassName,
}: {
  code: OfferStatusEnum | OfferErrorCodeEnum | null;
  eventShortId: string;
  linkClassName?: string;
  paragraphClassName?: string;
}) => {
  if (code === OfferStatusEnum.RegistrationNotOpened || code === OfferErrorCodeEnum.NOT_OPENED) {
    return (
      <>
        <p className={paragraphClassName}>
          <Trans id="page.ticket.error.notOpened.message">
            Désolé, les inscriptions ne sont pas encore ouvertes pour cette offre.
          </Trans>
        </p>
        <p>
          <Link className={linkClassName} to={eventShortId ? `/event/${eventShortId}` : "/"}>
            <Trans id="page.ticket.error.seeOffersButton">Voir toutes les offres</Trans>
          </Link>
        </p>
      </>
    );
  }
  if (code === OfferStatusEnum.Expired || code === OfferErrorCodeEnum.EXPIRED) {
    return (
      <>
        <p className={paragraphClassName}>
          <Trans id="page.ticket.error.expired.message">L'offre a expiré 🙁</Trans>
        </p>
        <p>
          <Link className={linkClassName} to={eventShortId ? `/event/${eventShortId}` : "/"}>
            <Trans id="page.ticket.error.seeOffersButton">Voir toutes les offres</Trans>
          </Link>
        </p>
      </>
    );
  }
  if (code === OfferStatusEnum.SoldOut || code === OfferErrorCodeEnum.SOLD_OUT) {
    return (
      <>
        <p className={paragraphClassName}>
          <Trans id="page.ticket.error.soldOut.message">
            Tous les dossards ont déjà été vendus 🙁
          </Trans>
        </p>
        <p>
          <Link className={linkClassName} to={eventShortId ? `/event/${eventShortId}` : "/"}>
            <Trans id="page.ticket.error.seeOffersButton">Voir toutes les offres</Trans>
          </Link>
        </p>
      </>
    );
  }
  if (code === OfferErrorCodeEnum.UNAVAILABLE) {
    return (
      <>
        <p className={paragraphClassName}>
          <Trans id="page.ticket.error.unavailable.message">L'offre n'est plus disponible 🙁</Trans>
        </p>
        <p>
          <Link className={linkClassName} to={eventShortId ? `/event/${eventShortId}` : "/"}>
            <Trans id="page.ticket.error.seeOffersButton">Voir toutes les offres</Trans>
          </Link>
        </p>
      </>
    );
  }

  return <></>;
};

export default OrderErrorMessage;
