"use client";

import { FC } from "react";
import { useLingui } from "@lingui/react";
import isArray from "lodash/isArray";

import { GenericDiscipline } from "@kavval/constants";
import { translateDiscipline } from "@kavval/formatters";

interface OneDisciplineProps {
  discipline: GenericDiscipline;
}

const OneDiscipline: FC<OneDisciplineProps> = ({ discipline }) => {
  const { i18n } = useLingui();

  return <span>{translateDiscipline(i18n, discipline)}</span>;
};

interface DisciplineProps {
  discipline: GenericDiscipline | GenericDiscipline[];
  separator?: string;
}

const Discipline: FC<DisciplineProps & OneDisciplineProps> = ({
  separator = ", ",
  discipline,
  ...props
}) => {
  if (isArray(discipline)) {
    return (
      <>
        {discipline.map((d) => <OneDiscipline key={d} {...props} discipline={d} />).join(separator)}
      </>
    );
  }

  return <OneDiscipline discipline={discipline} {...props} />;
};

export default Discipline;
