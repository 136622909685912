import { ReactNode } from "react";
import { Plural, Trans } from "@lingui/macro";
import { differenceInMinutes } from "date-fns";

import { formatTime } from "@kavval/formatters";
import { Maybe } from "@kavval/ts-utils";

const MINUTES_PER_DAY = 24 * 60;
// const MINUTES_PER_MONTH = 31 * MINUTES_PER_DAY;

export const offerIsLimited = (offer: {
  remainingRegistrations?: Maybe<number>;
  expiresAt?: Maybe<string>;
}) =>
  offer.remainingRegistrations ||
  (offer.expiresAt &&
    differenceInMinutes(new Date(offer.expiresAt), new Date()) < 14 * MINUTES_PER_DAY);

const useOfferAvailabilityMessages = ({
  expiresAt,
  remainingRegistrations,
  bookable,
}: {
  expiresAt: Maybe<string>;
  remainingRegistrations: Maybe<number>;
  bookable: boolean;
}) => {
  const result: ReactNode[] = [];

  if (!bookable) {
    return result;
  }

  if (expiresAt) {
    const diff = differenceInMinutes(new Date(expiresAt), new Date());
    if (diff <= 0) {
      // nothing
    } else if (diff <= 2 * MINUTES_PER_DAY) {
      const duration = formatTime({ time: diff });
      result.push(<Trans id="page.tickets.offerWarning.lastHours">Expire dans {duration}</Trans>);
    } else if (diff < 14 * MINUTES_PER_DAY) {
      const numberOfDays = Math.floor(diff / MINUTES_PER_DAY);
      result.push(
        <Trans id="page.tickets.offerWarning.lastDays">Expire dans {numberOfDays} jours</Trans>
      );
    } /* else if (diff < 14 * MINUTES_PER_MONTH) {
      const durationInWeeks = Math.floor(diff / (MINUTES_PER_DAY * 7));
      result.push(
        <Trans id="page.tickets.offerWarning.lastWeeks">
          Expire dans {durationInWeeks} semaines
        </Trans>
      );
    } else {
      const durationInMonths = Math.floor(diff / MINUTES_PER_MONTH);
      result.push(
        <Trans id="page.tickets.offerWarning.lastWeeks">Expire dans {durationInMonths} mois</Trans>
      );
    } */
  }

  if (remainingRegistrations) {
    if (remainingRegistrations <= 10) {
      result.push(
        <Plural
          id="page.tickets.offerWarning.lastBibsExact"
          value={remainingRegistrations}
          one="Plus qu'un dossard disponible !"
          other="Plus que # dossards disponibles !"
        />
      );
    } else if (remainingRegistrations <= 500) {
      result.push(
        <Trans id="page.tickets.offerWarning.lastBibsLessThan">
          Moins de {remainingRegistrations} dossards disponibles
        </Trans>
      );
    }
  }

  return result;
};

export default useOfferAvailabilityMessages;
