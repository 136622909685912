import { ReactNode } from "react";
import clsx from "clsx";

type ErrorBlockProps = { children?: ReactNode; title?: string; className?: string };

const ErrorBlock = ({ children, title, className }: ErrorBlockProps) => {
  return (
    <div
      className={clsx(
        "p-4 md:p-8 bg-white border-t-4 border-orange rounded-sm shadow-md text-sm md:text-base",
        className
      )}
    >
      {title && <div className="font-bold text-base text-orange md:text-lg ">{title}</div>}
      {children}
    </div>
  );
};

export default ErrorBlock;
