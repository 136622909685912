function createCloudinaryURLForType(
  publicID: string,
  transformation: string[],
  type?: "upload" | "fetch"
): string;

function createCloudinaryURLForType(
  publicID: null | "",
  transformation: string[],
  type?: "upload" | "fetch"
): null;

function createCloudinaryURLForType(
  publicID: string | null,
  transformation: string[],
  type: "upload" | "fetch" = "upload"
): string | null {
  if (!publicID) return null;

  const prefix = "https://res.cloudinary.com/kavval";
  let transformationString = transformation ? [...transformation].toString() : "f_auto";

  if (!/f_/.test(transformationString)) {
    transformationString = `${transformationString},f_auto`;
  }

  const url = [prefix, "image", type, transformationString, publicID]
    .filter((a) => a)
    .join("/")
    .replace(" ", "%20");

  return url;
}

export function createCloudinaryURL(publicID: string, transformation: string[]) {
  return createCloudinaryURLForType(publicID, transformation, "upload");
}

export function createCloudinaryFetchURL(publicID: string, transformation: string[]) {
  return createCloudinaryURLForType(encodeURIComponent(publicID), transformation, "fetch");
}

export const getFlagUrl = (countryCode: string, height = 16) =>
  createCloudinaryURL(`app/country-flags/${countryCode}`, [
    `h_${height}`,
    "g_auto",
    "c_lfill",
    "q_auto",
  ]);

const localeToCountryCode = {
  en: "gb",
  da: "dk",
  el: "gr",
  cs: "cz",
  sv: "se",
};

export const getLocaleFlagUrl = (countryCode: string, height = 16) => {
  return getFlagUrl(
    localeToCountryCode[countryCode as keyof typeof localeToCountryCode] || countryCode,
    height
  );
};
