import { ReactNode } from "react";
import clsx from "clsx";

import useTrackPageView from "@booking/lib/hooks/useTrackPageView";

import styles from "./styles.module.css";

const NotFoundMessage = ({
  title,
  children,
  tracking,
}: {
  title: ReactNode;
  children: ReactNode;
  tracking: Parameters<typeof useTrackPageView>[0];
}) => {
  useTrackPageView(tracking);

  return (
    <div className={clsx("text-center mt-8 md:mt-12", styles.Container)}>
      <div className="max-w-2xl mx-auto font-bold text-3xl md:text-4xl tracking-tight">{title}</div>
      <div className="max-w-2xl mx-auto">{children}</div>
    </div>
  );
};

export default NotFoundMessage;
