import { useEffect } from "react";
import { isEmpty, pick } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { ProfileFields } from "@booking/lib/hooks/useSaveProfile";
import { Output } from "@booking/lib/trpc";
import { BookingRoutePathEnum } from "@booking/pages/Booking";
import { FormValues } from "@booking/pages/Booking/Details";

const saveState = (state: BookingState) => {
  try {
    localStorage.setItem(
      `ticket:${state.ticketShortId}`,
      JSON.stringify(pick(state, ["orderId", "user", "detailsForm"]))
    );
  } catch (e) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.warn("could not save state to localStorage");
  }
};

export const clearLocalStorage = (ticketShortId: string) => {
  try {
    localStorage.removeItem(`ticket:${ticketShortId}`);
  } catch (e) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.warn("could not clear localStorage");
  }
};

export const stateInitializer = (initialState: Partial<BookingState>) => {
  const { ticketShortId } = initialState;
  let savedState = {} as BookingState;

  try {
    // @ts-ignore
    savedState = JSON.parse(localStorage.getItem(`ticket:${ticketShortId}`)) as BookingState;
  } catch (e) {
    // nothing
  }

  const newState: BookingState = {
    user: undefined,
    ticket: undefined,
    // @ts-ignore
    detailsForm: null,
    // @ts-ignore
    currentStep: BookingRoutePathEnum.Auth,
    ...initialState,
    ...savedState,
    orderId: savedState?.orderId || uuidv4(),
  };

  if (newState.user) {
    newState.currentStep = BookingRoutePathEnum.Details;
  }

  saveState(newState);

  return newState;
};

export const useStateLocalStorage = (state: BookingState) => {
  useEffect(() => {
    saveState(state);
  }, [state]);
};

enum ActionType {
  SET_USER = "SET_USER",
  SET_TICKET = "SET_TICKET",
  CONFIRM_DETAILS = "CONFIRM_DETAILS",
  SAVE_DETAILS = "SAVE_DETAILS",
  RESET_ORDER_ID = "RESET_ORDER_ID",
}

export const PROFILE_FIELDS = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "birthdate",
  "genre",
] as const;

export type User = NonNullable<Output["getProfile"]>;
type Ticket = NonNullable<Output["getOfferByShortId"]>;
type RaceEdition = NonNullable<Ticket["raceEdition"]>;
type Race = NonNullable<RaceEdition["race"]>;
type Event = NonNullable<Race["event"]>;

export type BookingState = {
  ticketShortId: string;
  orderId: string;
  user?: User;
  ticket?: Ticket;
  // FIXME later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailsForm: FormValues;
  currentStep: BookingRoutePathEnum;
};

// Action creators
export const setUser = (payload?: User | null) => ({ type: ActionType.SET_USER, payload }) as const;
export const setTicket = (payload: Ticket) => ({ type: ActionType.SET_TICKET, payload }) as const;
export const confirmDetails = (payload: BookingState["detailsForm"]) =>
  ({
    type: ActionType.CONFIRM_DETAILS,
    payload,
  }) as const;
export const saveDetails = (payload: BookingState["detailsForm"]) =>
  ({
    type: ActionType.SAVE_DETAILS,
    payload,
  }) as const;
export const resetOrderId = () =>
  ({ type: ActionType.RESET_ORDER_ID, payload: undefined }) as const;

// Selectors
export const isAuthenticated = (state: BookingState) => state?.user;
export const isAnonymous = (state: BookingState) => !!state.user?.isAnonymous;
export const isAffiliation = (state: BookingState) =>
  !!(state.ticket?.sellerId && /affiliation/.test(state.ticket?.sellerId));

export const getCartDetails = (state: BookingState) => {
  const { ticket } = state;

  if (!ticket) {
    return { items: [] };
  }

  return {
    items: [
      {
        offer: state.ticket!,
        options: ticket.options.filter((option) => state.detailsForm?.options?.[option.id]),
      },
    ],
  };
};

export const getSaveOrderPayload = (state: BookingState) => {
  const {
    ticket,
    user,
    orderId,
    detailsForm: { participant: participantId, participantDetails, options },
  } = state;
  const formData: Partial<ProfileFields> = pick(participantDetails, [
    ...PROFILE_FIELDS,
    "customFields",
  ]);

  const userData =
    user!.isAnonymous || participantId === "me" ? { userId: user!.id } : { id: participantId! };

  return {
    id: orderId,
    items: [
      {
        offerId: ticket!.id,
        participant: {
          ...formData,
          ...userData,
        },
        options: !options
          ? []
          : Object.keys(options).reduce(
              (result, optionId) => {
                if (options[optionId]) {
                  result.push({ optionId });
                }
                return result;
              },
              [] as Array<{ optionId: string }>
            ),
      },
    ],
  };
};

export const getCartAmount = (cart: ReturnType<typeof getCartDetails>) => {
  // Dans le cas des inscriptions sur liste d'attente on n'a pas de prix.
  // Dans ces cas on laisse undefined dans le total

  return cart.items.reduce(
    (count, item) => {
      if (typeof item.offer.price !== "number") return count;

      const optionsTotal = (item.options || []).reduce(
        (count, option) => (count || 0) + option.price,
        undefined as undefined | number
      );

      return (count || 0) + (item.offer.price || 0) + (item.offer.fees || 0) + (optionsTotal || 0);
    },
    undefined as undefined | number
  );
};

export type BookingAction =
  | ReturnType<typeof setUser>
  | ReturnType<typeof setTicket>
  | ReturnType<typeof confirmDetails>
  | ReturnType<typeof saveDetails>
  | ReturnType<typeof resetOrderId>;

const currentStepReducer = (state: BookingState, { type, payload }: BookingAction) => {
  if (type === ActionType.SET_USER) {
    return !payload ? BookingRoutePathEnum.Auth : BookingRoutePathEnum.Details;
  }
  if (type === ActionType.CONFIRM_DETAILS) {
    return BookingRoutePathEnum.Payment;
  }

  return state.currentStep;
};

export const reducer = (state: BookingState, { type, payload }: BookingAction): BookingState => {
  if (type === ActionType.RESET_ORDER_ID) {
    return {
      ...state,
      orderId: uuidv4(),
    } satisfies BookingState;
  }
  if (type === ActionType.SET_USER) {
    const newState: BookingState = { ...state };

    if (!payload) {
      newState.user = undefined;
      newState.currentStep = currentStepReducer(state, { type, payload });
      newState.detailsForm = {};
    } else {
      newState.user = payload;
      if (state.user?.id && state.user?.id !== newState.user?.id) {
        // Le cas peut se produire lorsqu'on commence une résa,
        // puis qu'on se déconnecte et reconnecte avec un autre compte.
        // Dans ce cas on réinitialise les données du formulaire ainsi que le numéro de commande.
        newState.orderId = uuidv4();
        newState.detailsForm = {};
      }

      newState.currentStep = currentStepReducer(state, { type, payload });

      if (isEmpty(state.detailsForm)) {
        newState.detailsForm = {
          saveParticipant: true,
          participant: "me",
          participantDetails: {
            firstName: payload.firstName || undefined,
            lastName: payload.lastName || undefined,
            email: payload.email || undefined,
            phone: payload.phone || undefined,
            birthdate: payload.birthdate || undefined,
            genre: payload.genre || undefined,
            newsletter: payload.newsletter || undefined,
          },
        };
      }

      // if (payload.isAnonymous) {
      //   newState.detailsForm.participant = "me";
      // }
    }

    return newState;
  }
  if (type === ActionType.SET_TICKET) {
    const p = payload as Ticket;
    return {
      ...state,
      ticket: p,
    };
  }
  if (type === ActionType.CONFIRM_DETAILS) {
    return {
      ...state,
      detailsForm: payload as BookingState["detailsForm"],
      currentStep: currentStepReducer(state, { type, payload }),
    };
  }
  if (type === ActionType.SAVE_DETAILS) {
    return {
      ...state,
      detailsForm: payload as BookingState["detailsForm"],
    };
  }

  return state;
};
