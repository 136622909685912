const MailtoSupportLink = ({ message = "", children }) => {
  return (
    <a
      className="font-semibold underline cursor-pointer"
      href={`mailto:contact@finishers.com?body=${encodeURIComponent(message)}`}
    >
      {children}
    </a>
  );
};

export default MailtoSupportLink;
