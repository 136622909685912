import { InternalTranslations } from "../types";

export const messages: InternalTranslations = {
  "urls.searchBaseUrl": "/courses",
  "urls.eventBaseUrl": "/course",
  "urls.tagsBaseUrl": "/thematiques",
  "urls.destinationsBaseUrl": "/ou-courir",
  "urls.destinationsHubBaseUrl": "/destinations",
  "urls.blogBaseUrl": "/blog",
  "urls.disciplinesBaseUrl": "/disciplines",
  "urls.resultsPath": "resultats",
  "shared.PhoneNumbers.support": "+33 4 51 42 01 21",
  "shared.Emails.contact": "contact@finishers.com",
  "shared.Emails.support": "contact@finishers.com",
  "shared.Emails.organizers.contact": "organisateur@finishers.com",
};
