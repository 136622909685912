"use client";

import { ComponentProps, ComponentRef, forwardRef } from "react";
import { useLingui } from "@lingui/react";
import clsx from "clsx";

import { GenericDiscipline } from "@kavval/constants";
import { translateDiscipline } from "@kavval/formatters";

import Chip from "../Chip";

interface Props extends ComponentProps<typeof Chip> {
  discipline: GenericDiscipline;
}

const DisciplineChip = forwardRef<ComponentRef<typeof Chip>, Props>(
  ({ size, discipline, className, ...props }, ref) => {
    const { i18n } = useLingui();

    return (
      <Chip
        size={size}
        className={clsx("bg-dark-blue text-white uppercase", className)}
        ref={ref}
        {...props}
      >
        <span>{translateDiscipline(i18n, discipline)}</span>
      </Chip>
    );
  }
);

DisciplineChip.displayName = "DisciplineChip";

export default DisciplineChip;
