import { t, Trans } from "@lingui/macro";

import NotFoundMessage from "@booking/components/NotFoundMessage";
import PageWrapper from "@booking/components/PageWrapper";

const NotFoundPage = ({ pageType }: { pageType: string }) => (
  <PageWrapper>
    <NotFoundMessage
      title={t({ id: "page.ticket.error.notFound.title", message: "Page non trouvée" })}
      tracking={{ pageType }}
    >
      <p>
        <Trans id="page.ticket.error.notFound.message">Désolé, cette page n'existe pas</Trans>
      </p>
      <p>
        <a className="button button-primary mt-8" href="/">
          <Trans id="page.ticket.error.notFound.backToHomePageButton">Revenir à l'accueil</Trans>
        </a>
      </p>
    </NotFoundMessage>
  </PageWrapper>
);

export default NotFoundPage;
