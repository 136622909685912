import { FC } from "react";
import { t } from "@lingui/macro";
import castArray from "lodash/castArray";
import difference from "lodash/difference";
import intersection from "lodash/intersection";

import { Chip, RaceTag } from "../..";
import MoreTags from "../MoreTags";
import styles from "./styles.module.css";

interface RaceTagsProps {
  tags: string[] | null;
  highlights?: (string | string[]) | null;
  boost?: boolean | null;
}

const RaceTags: FC<RaceTagsProps> = (props) => {
  const boost = props.boost;
  const tags = castArray(props.tags).filter(Boolean) as string[];
  const selection = castArray(props.highlights).filter(Boolean) as string[];
  const shortTags = intersection(tags, selection);
  let first2ShortTags = shortTags.slice(0, 2);
  let otherTags = difference(tags, selection).concat(shortTags.slice(2));

  if (tags.length === 1) {
    otherTags = tags;
    first2ShortTags = [];
  }

  return (
    <>
      {boost && (
        <Chip size="small" color="secondary" className={styles.MustSee}>
          👀
          <span className="MustSeeLabel">
            &nbsp;
            {t({ id: "generic.tags.mustSee", message: "À ne pas manquer !" })}
          </span>
        </Chip>
      )}
      {first2ShortTags.map((tag) => (
        <Chip key={tag} size="small">
          <RaceTag emojiOnly tag={tag} />
        </Chip>
      ))}
      {otherTags.length ? (
        <Chip size="small">
          <RaceTag tag={otherTags[0]} />
        </Chip>
      ) : null}
      {otherTags.length > 1 ? <MoreTags tags={otherTags.slice(1)} /> : null}
    </>
  );
};

export default RaceTags;
