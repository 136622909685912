export enum UtmbEventType {
  Events = "events",
  Finals = "finals",
  Majors = "majors",
  Qualifiers = "qualifiers",
}

const values: string[] = Object.keys(UtmbEventType).map(
  (key) => UtmbEventType[key as keyof typeof UtmbEventType]
);

export default values;
